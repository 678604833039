import { useAtom } from "jotai";
import { userAtom } from "../states/invoicesState";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DraftsIcon from '@mui/icons-material/Drafts';
import TaskIcon from '@mui/icons-material/Task';
import ErrorIcon from '@mui/icons-material/Error';
import Paper from "@mui/material/Paper";
import Appbar from "../../../components/AppBar/AppBar";
import { useEffect, useRef, useState } from "react";
import DraftUbvoiceList from "../components/DraftInvoiceList";
import CompletedInvoiceList from "../components/CompletedInvoiceList";
import FailedInvoiceList from "../components/FailedInvoiceList";
import Loader from "../../../components/Loader";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import MenuButton from "../../../components/AppBar/MenuButton";

export default function InvoiceHomePage() {
  const [user] = useAtom(userAtom);
  const Navigate = useNavigate();
  const [invoiceType, setInvoiceType] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user) {
      return Navigate("/login");
    }
  }, [user, Navigate]);

  if (!user) {
    return <Loader />;
  }


  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Appbar backButtonVisible={false}>
        <MenuButton text="Invoices" url="/" />
        <MenuButton text="Debit-Credit Notes" url="/debit-credit-note-homepage" />
      </Appbar>
      <CssBaseline />
      {invoiceType === 0 && <DraftUbvoiceList />}
      {invoiceType === 1 && <CompletedInvoiceList />}
      {invoiceType === 2 && <FailedInvoiceList />}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={invoiceType}
          onChange={(event, newValue) => {
            setInvoiceType(newValue);
          }}
        >
          <BottomNavigationAction label="Draft" icon={<DraftsIcon />} />
          <BottomNavigationAction label="Completed" icon={<TaskIcon />} />
          <BottomNavigationAction label="Failed" icon={<ErrorIcon />} />
        </BottomNavigation>
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "absolute", bottom: 32, right: 32 }}
        onClick={() => {
          Navigate("/draftinvoiceform");
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
