import { useAtom } from "jotai";
import { allowanceAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function Allowance() {
    const [allowance, setAllowance] = useAtom(allowanceAtom);

    return <TextField
    id="outlined-basic"
    label="Allowance"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={allowance}
    onChange={(e) => {
      setAllowance(e.target.value);
    }}
  />;

}

export default Allowance;