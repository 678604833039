import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { transporterAtom } from "../../states/invoicesState";

function Transporter() {
    const [transporter, setTransporter] = useAtom(transporterAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Transporter"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={transporter}
            onChange={(e) => {
              setTransporter(e.target.value);
            }}
          />
    );
}

export default Transporter;