import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pickUpAddressAtom } from "../../states/invoicesState";

function PickUpAddress() {
    const [pickUpAddress, setPickUpAddress] = useAtom(pickUpAddressAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Address"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pickUpAddress}
            onChange={(e) => {
              setPickUpAddress(e.target.value);
            }}
          />
    );
}

export default PickUpAddress;