import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { truckNumberAtom } from "../../states/invoicesState";

function TruckNumber() {
    const [truckNumber, setTruckNumber] = useAtom(truckNumberAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Truck No."
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={truckNumber}
            onChange={(e) => {
              setTruckNumber(e.target.value);
            }}
          />
    );
}

export default TruckNumber;