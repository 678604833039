import { useAtom } from "jotai";
import { sellerDtlsStcdAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsStcd() {
    const [sellerDtlsStcd, setSellerDtlsStcd] = useAtom(sellerDtlsStcdAtom);

    return <TextField
    id="outlined-basic"
    label="Stcd"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsStcd}
    onChange={(e) => {
        setSellerDtlsStcd(e.target.value);
    }}
  />;
};

export default SellerDtlsStcd;