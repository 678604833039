import { useNavigate } from "react-router-dom";
import { otpAtom, userAtom } from "../states/invoicesState";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HomeIcon from "@mui/icons-material/Home";
import { handleHomeButton } from "../helpers";
import "./ShareInvoicePage.css";

const SHARE_URL = "https://weighment.ajdalal.com";

const ShareInvoicePage = () => {
  const Navigate = useNavigate();
  const [user] = useAtom(userAtom);
  const [otp] = useAtom(otpAtom);
  const [showCopySnackBar, setShowCopySnackBar] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      return Navigate("/login");
    }
  }, [user, Navigate]);

  if (!user) {
    return <Loader />;
  }

  const copy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setShowCopySnackBar(true);
  };

  function handleHomeButtonClick() {
    handleHomeButton(Navigate);
  }
  // console.log("otp:", otp);
  return (
    <Box
      sx={{ width: "96vw", height: "96vh", padding: "2vh 2vw" }}
      className="center-everything"
    >
      <Alert
        severity="success"
        sx={{ fontSize: "large", paddingBottom: "16px", maxWidth: "600px" }}
        variant="filled"
        className="success-alert"
      >
        <AlertTitle sx={{ fontSize: 40, marginBottom: 0 }}>Success</AlertTitle>
        <Typography>Your invoice has been created successfully.</Typography>
        <Typography fontWeight={800}>
          you should share this link and OTP to obtain weight and weighment
          slip.
        </Typography>
      </Alert>
      <br />
      <div className="alert-container">
        <Alert
          variant="outlined"
          severity="info"
          id="link-otp"
          className="success-alert"
          sx={{ fontSize: 35 }}
          action={
            <IconButton
              sx={{ float: "right" }}
              aria-label="copy"
              onClick={() => {
                copy(
                  `Link: ${SHARE_URL} 
OTP: ${otp}`
                );
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          }
        >
          <Typography
            sx={{ width: "100%", marginTop: "8px", paddingBottom: "16px" }}
            variant="h4"
          >
            Link: <Link href={SHARE_URL}>{SHARE_URL}</Link>
            <br />
            OTP: {otp}
          </Typography>
        </Alert>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showCopySnackBar}
          autoHideDuration={2000}
          onClose={() => {
            setShowCopySnackBar(false);
          }}
          message="Link & OTP has been copied"
        />
      </div>
      <br />
      <br />
      <Button
        variant="contained"
        endIcon={<HomeIcon />}
        onClick={() => {
          handleHomeButtonClick();
        }}
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default ShareInvoicePage;
