import { useAtom } from "jotai";
import { itemListIsServcAtom } from "../../states/DebitCreditNotesState";
import Select from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

function ItemListIsServc() {
    const [itemListIsServc, setItemListIsServc] = useAtom(itemListIsServcAtom);

    return (
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Is this Service</InputLabel>
    <Select
    id="demo-simple-select"
    label="Type"
    labelId="demo-simple-select-label"
    variant="outlined"
    value={itemListIsServc}
    fullWidth
    onChange={(e) => {
      setItemListIsServc(e.target.value);
    }}
  >
    <MenuItem value="N">No</MenuItem>
    <MenuItem value="Y">Yes</MenuItem>
  </Select></FormControl>);
};

export default ItemListIsServc;