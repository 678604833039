import { useAtom } from "jotai";
import { userAtom } from "../../invoices/states/invoicesState";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Loader";
import Box from "@mui/material/Box";
import Appbar from "../../../components/AppBar/AppBar";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import DebitCreditNoteList from "../components/DebitCreditNoteList";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import MenuButton from "../../../components/AppBar/MenuButton";
import { DebitCreditNoteType } from "../models";

export default function DebitCreditNoteHomePage() {
  const [user] = useAtom(userAtom);
  const Navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [docType, setDocType] = useState(DebitCreditNoteType.CreditNote);

  useEffect(() => {
    if (!user) {
      return Navigate("/login");
    }
  }, [user, Navigate]);

  if (!user) {
    return <Loader />;
  }

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Appbar backButtonVisible={false}>
        <MenuButton text="Invoices" url="/" />
        <MenuButton
          text="Debit-Credit Notes"
          url="/debit-credit-note-homepage"
        />
      </Appbar>

      {docType === DebitCreditNoteType.DebitNote && (
        <DebitCreditNoteList docType={DebitCreditNoteType.DebitNote} />
      )}
      {docType === DebitCreditNoteType.CreditNote && (
        <DebitCreditNoteList docType={DebitCreditNoteType.CreditNote} />
      )}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={docType}
          onChange={(event, newValue) => {
            setDocType(newValue);
          }}
        >
          <BottomNavigationAction
            value={DebitCreditNoteType.CreditNote}
            label="Credit Notes"
          />
          <BottomNavigationAction
            value={DebitCreditNoteType.DebitNote}
            label="Debit Notes"
          />
        </BottomNavigation>
      </Paper>
      <Fab
        variant="extended"
        color="primary"
        aria-label="createDebitCreditNote"
        sx={{ position: "absolute", bottom: 32, right: 32 }}
        onClick={() => {
          Navigate("/create-debit-credit-note");
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        DN - CN
      </Fab>
    </Box>
  );
}
