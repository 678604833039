import Box from "@mui/material/Box";
import LoginWithEmailPassword from "../components/LogInWithEmailPassword";
import LogInWithGoogle from "../components/LogInWithGoogle";
import "./LogInPage.css";
import { useAtom } from "jotai";
import { userAtom } from "../../invoices/states/invoicesState";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../components/Loader";

const LogInPage = () => {
  const [user] = useAtom(userAtom);
  const Navigate = useNavigate();

  useEffect(() => {
    if (user) {
      return Navigate("/");
    }
  }, [user, Navigate]);

  if (typeof user === "undefined" || user) {
    return <Loader />
  }

  return (
    <Box className="full-width-height center-everything">
      <LoginWithEmailPassword />
      <hr className="login-form-seperator" />
      <LogInWithGoogle />
    </Box>
  );
};

export default LogInPage;
