import { useAtom } from "jotai";
import { companyNameAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";
import { Company } from "../../../../models";

function CompanyName() {
    const [companyName, setCompanyName] = useAtom(companyNameAtom);

    return <TextField
    id="outlined-basic"
    label="Comapny"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={companyName}
    onChange={(e) => {
        setCompanyName(e.target.value as Company);
    }}
  />;
};

export default CompanyName;