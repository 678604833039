import { useAtom } from "jotai";
import { noteAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function Note() {
  const [note, setNote] = useAtom(noteAtom);

  return (
    <TextField
      id="outlined-basic"
      label="Note"
      variant="outlined"
      autoComplete="off"
      fullWidth
      value={note}
      onChange={(e) => {
        setNote(e.target.value);
      }}
    />
  );
}

export default Note;
