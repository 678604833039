import { useAtom } from "jotai";
import { buyerDtlsStcdAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsStcd() {
    const [buyerDtlsStcd, setBuyerDtlsStcd] = useAtom(buyerDtlsStcdAtom);

    return <TextField
    id="outlined-basic"
    label="Stcd"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsStcd}
    onChange={(e) => {
          setBuyerDtlsStcd(e.target.value);
    }}
  />;
};

export default BuyerDtlsStcd;