import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { finalAmountAtom } from "../../states/invoicesState";

function FinalAmount() {
    const [finalAmount, setFinalAmount] = useAtom(finalAmountAtom);

    return (
        <TextField
            id="outlined-basic"
            label="FINAL AMOUNT"
            variant="filled"
            autoComplete="off"
            fullWidth
            type="number"
            value={finalAmount}
            onChange={(e) => {
              setFinalAmount(e.target.value);
            }}
          />
    );

}

export default FinalAmount;