import { Grid } from "@mui/material";
import BuyerName from "../InvoiceDetails/BuyerName";
import Company from "../InvoiceDetails/Company";
import ContractNumber from "../InvoiceDetails/ContractNumber";
import DocumentNumber from "../InvoiceDetails/DocumentNumber";
import InvoiceDate from "../InvoiceDetails/InvoiceDate";
import InvoiceNumber from "../InvoiceDetails/InvoiceNumber";
import Material from "../InvoiceDetails/Material";
import PressNumber1 from "../InvoiceDetails/PressNumber1";
import PressNumber2 from "../InvoiceDetails/PressNumber2";
import PressNumber3 from "../InvoiceDetails/PressNumber3";
import PressNumber4 from "../InvoiceDetails/PressNumber4";
import PressNumber5 from "../InvoiceDetails/PressNumber5";
import PressNumber6 from "../InvoiceDetails/PressNumber6";
import Station from "../InvoiceDetails/Station";

function InvoiceDetails() {
  return (
    <Grid container>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <InvoiceDate />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="contract-number">
        <ContractNumber />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-number">
        <InvoiceNumber />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="document number">
        <DocumentNumber />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="company">
        <Company />
      </Grid>

      <Grid xs={12} sm={6} md={5} padding="20px" className="buyer-name">
        <BuyerName />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="material">
        <Material />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="station">
        <Station />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="press-number-1">
        <PressNumber1 />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="press-number-2">
        <PressNumber2 />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="press-number-3">
        <PressNumber3 />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="press-number-4">
        <PressNumber4 />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="press-number-5">
        <PressNumber5 />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="press-number-6">
        <PressNumber6 />
      </Grid>
    </Grid>
  );
}

export default InvoiceDetails;
