import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

type MenuButtonProps = {
    text: string;
    url: string;
}

const MenuButton: FC<MenuButtonProps> = ({text, url}) => {
  const Navigate = useNavigate();

  return (
        <Button
          color="inherit"
          onClick={() => {Navigate(url)}}
        >
          <Typography component="div" sx={{ flexGrow: 1, fontWeight: "bold" }}>
            {text}
          </Typography>
        </Button>
  );
}

export default MenuButton;
