import { useAtom } from "jotai";
import {
    itemListHsnCdAtom,
    docDtlsDtAtom,
    buyerDtlsStcdAtom,
    buyerDtlsLglNmAtom,
    buyerDtlsGstinAtom,
    buyerDtlsPinAtom,
    buyerDtlsTrdNmAtom,
    buyerDtlsAddr1Atom,
    buyerDtlsLocAtom,
    buyerDtlsPosAtom,
    sellerDtlsStcdAtom,
    sellerDtlsLglNmAtom,
    sellerDtlsGstinAtom,
    sellerDtlsPinAtom,
    sellerDtlsTrdNmAtom,
    sellerDtlsAddr1Atom,
    sellerDtlsAddr2Atom,
    sellerDtlsLocAtom,
    PrecDocDtlsInvNoAtom,
    PrecDocDtlsInvDtAtom,
    companyNameAtom
} from "../states/DebitCreditNotesState";
import dayjs, { Dayjs } from "dayjs";
import { getOurCompanyData } from "../../../helpers/getCompanyData";
import stateNameData from "../../../helpers/StateNametoStateCodeData";

function usePastedInvoiceData() {
    const [, setItemListHsnCd] = useAtom(itemListHsnCdAtom);
    const [, setDocDtlsDt] = useAtom(docDtlsDtAtom);
    const [, setBuyerDtlsStcd] = useAtom(buyerDtlsStcdAtom);
    const [, setBuyerDtlsLglNm] = useAtom(buyerDtlsLglNmAtom);
    const [, setBuyerDtlsGstin] = useAtom(buyerDtlsGstinAtom);
    const [, setBuyerDtlsPin] = useAtom(buyerDtlsPinAtom);
    const [, setBuyerDtlsTrdNm] = useAtom(buyerDtlsTrdNmAtom);
    const [, setBuyerDtlsAddr1] = useAtom(buyerDtlsAddr1Atom);
    const [, setBuyerDtlsLoc] = useAtom(buyerDtlsLocAtom);
    const [, setBuyerDtlsPos] = useAtom(buyerDtlsPosAtom);
    const [, setSellerDtlsStcd] = useAtom(sellerDtlsStcdAtom);
    const [, setSellerDtlsLglNm] = useAtom(sellerDtlsLglNmAtom);
    const [, setSellerDtlsGstin] = useAtom(sellerDtlsGstinAtom);
    const [, setSellerDtlsPin] = useAtom(sellerDtlsPinAtom);
    const [, setSellerDtlsTrdNm] = useAtom(sellerDtlsTrdNmAtom);
    const [, setSellerDtlsAddr1] = useAtom(sellerDtlsAddr1Atom);
    const [, setSellerDtlsAddr2] = useAtom(sellerDtlsAddr2Atom);
    const [, setSellerDtlsLoc] = useAtom(sellerDtlsLocAtom);
    const [, setPrecDocDtlsInvNo] = useAtom(PrecDocDtlsInvNoAtom);
    const [, setPrecDocDtlsInvDt] = useAtom(PrecDocDtlsInvDtAtom);
    const [, setCompanyName] = useAtom(companyNameAtom);

    async function useCopiedContent() {
        await navigator.clipboard
           .readText()
           .then((copiedData) => {
             try {
               const data = JSON.parse(copiedData);

               if (data?.buyerName) {
                setBuyerDtlsLglNm(data.buyerName);
               }
               if (data?.toTraderName) {
                setBuyerDtlsTrdNm(data.toTraderName);
               }
               if(data?.toGstin) {
                setBuyerDtlsGstin(data.toGstin);
               }
               if(data?.toAddress) {
                setBuyerDtlsAddr1(data.toAddress);
               }
               if(data?.toPlace) {
                setBuyerDtlsLoc(data.toPlace);
               }
               if(data?.ToPin) {
                setBuyerDtlsPin(data.ToPin);
               }
               if(data?.ToState) {
                setBuyerDtlsStcd(stateNameData[data.ToState]);
                setBuyerDtlsPos(stateNameData[data.ToState]);
               }
               if(data?.company) {
                const CompanyData = getOurCompanyData(data.company);
                setSellerDtlsLglNm(CompanyData.fromTrdName);
                setSellerDtlsTrdNm(CompanyData.fromTrdName);
                setSellerDtlsGstin(CompanyData.fromGstin);
                setSellerDtlsAddr1(CompanyData.fromAddr1);
                setSellerDtlsAddr2(CompanyData.fromAddr2);
                setSellerDtlsPin(CompanyData.pincode);
                setSellerDtlsLoc(CompanyData.location);
                setCompanyName(CompanyData.fromTrdName);
                setSellerDtlsStcd(stateNameData[CompanyData.state]);
               }
               if (data?.invoiceDate) {
                const invoiceDate: Dayjs = dayjs(new Date(data.invoiceDate));
                setDocDtlsDt(invoiceDate);
              }
               if(data?.hsnCode) {
                setItemListHsnCd(data.hsnCode.toString());
               }
               if(data?.invoiceNumber) {
                setPrecDocDtlsInvNo(data.invoiceNumber);
               }
               if(data?.invoiceDate) {
                const invoiceDate: Dayjs = dayjs(new Date(data.invoiceDate));
                setPrecDocDtlsInvDt(invoiceDate);
               }
               if(data?.invoiceNumber) {
                const copiedInvoiceNo = data.invoiceNumber
                const splittedInvoiceNo = copiedInvoiceNo.split(":");
                setPrecDocDtlsInvNo(splittedInvoiceNo[1]);
               }
             } catch (e) {
               alert(e);
             }
           })
           .catch((err) => {
             console.error("Failed to read clipboard contents: ", err);
           });
       }

    return { useCopiedContent };
};

export default usePastedInvoiceData;