import { useAtom } from "jotai";
import { itemListHsnCdAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function ItemListHsnCd() {
    const [itemListHsnCd, setItemListHsnCd] = useAtom(itemListHsnCdAtom);

    return <TextField
    id="outlined-basic"
    label="HsnCd"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={itemListHsnCd}
    onChange={(e) => {
        setItemListHsnCd(e.target.value);
    }}
  />;
};

export default ItemListHsnCd;