import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pickUpStateAtom } from "../../states/invoicesState";

function PickUpState() {
    const [pickUpState, setPickUpState] = useAtom(pickUpStateAtom);

    return (
        <TextField
            id="outlined-basic"
            label="State"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pickUpState}
            onChange={(e) => {
              setPickUpState(e.target.value);
            }}
          />
    );
}

export default PickUpState;