import { useAtom } from "jotai";
import { buyerDtlsPosAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsPos() {
    const [buyerDtlsPos, setBuyerDtlsPos] = useAtom(buyerDtlsPosAtom);

    return <TextField
    id="outlined-basic"
    label="Pos"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsPos}
    onChange={(e) => {
          setBuyerDtlsPos(e.target.value);
    }}
  />;
};

export default BuyerDtlsPos;