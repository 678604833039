import { useAtom } from "jotai";
import { buyerNameAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function BuyerName() {
    const [buyerName, setBuyerName] = useAtom(buyerNameAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Buyer Name"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={buyerName}
            onChange={(e) => {
              setBuyerName(e.target.value);
            }}
          />
    );
}

export default BuyerName;