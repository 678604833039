import { useAtom } from "jotai";
import {
  valDtlsAssValueAtom,
  valDtlsCgstAtom,
  valDtlsSgstAtom,
  valDtlsIgstAtom,
  valDtlsCesValAtom,
  valDtlsStCesValAtom,
  valDtlsDiscountAtom,
  valDtlsOthChrgAtom,
  valDtlsRndOffAmtAtom,
  valDtlsTotInvValAtom,
  valDtlsTotInvValFcAtom,
  itemListSlNoAtom,
  itemListPrdDescAtom,
  itemListIsServcAtom,
  itemListHsnCdAtom,
  itemListQtyAtom,
  itemListFreeQtyAtom,
  itemListUnitAtom,
  itemListUnitPriceAtom,
  itemListTotAmtAtom,
  itemListDiscountAtom,
  itemListAssAmtAtom,
  itemListGstRtAtom,
  itemListIgstAmtAtom,
  itemListCgstAmtAtom,
  itemListSgstAmtAtom,
  itemListCesRtAtom,
  itemListCesAmtAtom,
  itemListCesNonAdvlAmtAtom,
  itemListStateCesRtAtom,
  itemListStateCesAmtAtom,
  itemListStateCesNonAdvlAmtAtom,
  itemListOthChrgAtom,
  itemListTotItemValAtom,
  tranDtlsRegRevAtom,
  tranDtlsEcmGstinAtom,
  tranDtlsSupTypAtom,
  tranDtlsTaxSchAtom,
  docDtlsDtAtom,
  docDtlsNoAtom,
  docDtlsTypAtom,
  versionAtom,
  buyerDtlsStcdAtom,
  buyerDtlsLglNmAtom,
  buyerDtlsGstinAtom,
  buyerDtlsPinAtom,
  buyerDtlsTrdNmAtom,
  buyerDtlsAddr1Atom,
  buyerDtlsAddr2Atom,
  buyerDtlsLocAtom,
  buyerDtlsPosAtom,
  sellerDtlsStcdAtom,
  sellerDtlsLglNmAtom,
  sellerDtlsGstinAtom,
  sellerDtlsPinAtom,
  sellerDtlsTrdNmAtom,
  sellerDtlsAddr1Atom,
  sellerDtlsAddr2Atom,
  sellerDtlsLocAtom,
  PrecDocDtlsInvNoAtom,
  PrecDocDtlsInvDtAtom,
  companyNameAtom,
  weightShortageAtom,
  trashExpAtom,
  sampleTestingAtom,
  cashDiscountAtom,
  tradeDiscountAtom,
  qualityAllowanceAtom,
  otherCostNameFieldAtom,
  otherCostValueFieldAtom,
  noteAtom
} from "../states/DebitCreditNotesState";

function useCreateDebitCreditNote(): [newDebitCreditNoteRestData: any] {
  const [valDtlsAssValue] = useAtom(valDtlsAssValueAtom);
  const [valDtlsCgst] = useAtom(valDtlsCgstAtom);
  const [valDtlsSgst] = useAtom(valDtlsSgstAtom);
  const [valDtlsIgst] = useAtom(valDtlsIgstAtom);
  const [valDtlsCesVal] = useAtom(valDtlsCesValAtom);
  const [valDtlsStCesVal] = useAtom(valDtlsStCesValAtom);
  const [valDtlsDiscount] = useAtom(valDtlsDiscountAtom);
  const [valDtlsOthChrg] = useAtom(valDtlsOthChrgAtom);
  const [valDtlsRndOffAmt] = useAtom(valDtlsRndOffAmtAtom);
  const [valDtlsTotInvVal] = useAtom(valDtlsTotInvValAtom);
  const [valDtlsTotInvValFc] = useAtom(valDtlsTotInvValFcAtom);
  const [itemListSlNo] = useAtom(itemListSlNoAtom);
  const [itemListPrdDesc] = useAtom(itemListPrdDescAtom);
  const [itemListIsServc] = useAtom(itemListIsServcAtom);
  const [itemListHsnCd] = useAtom(itemListHsnCdAtom);
  const [itemListQty] = useAtom(itemListQtyAtom);
  const [itemListFreeQty] = useAtom(itemListFreeQtyAtom);
  const [itemListUnit] = useAtom(itemListUnitAtom);
  const [itemListUnitPrice] = useAtom(itemListUnitPriceAtom);
  const [itemListTotAmt] = useAtom(itemListTotAmtAtom);
  const [itemListDiscount] = useAtom(itemListDiscountAtom);
  const [itemListAssAmt] = useAtom(itemListAssAmtAtom);
  const [itemListGstRt] = useAtom(itemListGstRtAtom);
  const [itemListIgstAmt] = useAtom(itemListIgstAmtAtom);
  const [itemListCgstAmt] = useAtom(itemListCgstAmtAtom);
  const [itemListSgstAmt] = useAtom(itemListSgstAmtAtom);
  const [itemListCesRt] = useAtom(itemListCesRtAtom);
  const [itemListCesAmt] = useAtom(itemListCesAmtAtom);
  const [itemListCesNonAdvlAmt] = useAtom(itemListCesNonAdvlAmtAtom);
  const [itemListStateCesRt] = useAtom(itemListStateCesRtAtom);
  const [itemListStateCesAmt] = useAtom(itemListStateCesAmtAtom);
  const [itemListStateCesNonAdvlAmt] = useAtom(itemListStateCesNonAdvlAmtAtom);
  const [itemListOthChrg] = useAtom(itemListOthChrgAtom);
  const [itemListTotItemVal] = useAtom(itemListTotItemValAtom);
  const [tranDtlsRegRev] = useAtom(tranDtlsRegRevAtom);
  const [tranDtlsEcmGstin] = useAtom(tranDtlsEcmGstinAtom);
  const [tranDtlsSupTyp] = useAtom(tranDtlsSupTypAtom);
  const [tranDtlsTaxSch] = useAtom(tranDtlsTaxSchAtom);
  const [docDtlsDt] = useAtom(docDtlsDtAtom);
  const [docDtlsNo] = useAtom(docDtlsNoAtom);
  const [docDtlsTyp] = useAtom(docDtlsTypAtom);
  const [version] = useAtom(versionAtom);
  const [buyerDtlsStcd] = useAtom(buyerDtlsStcdAtom);
  const [buyerDtlsLglNm] = useAtom(buyerDtlsLglNmAtom);
  const [buyerDtlsGstin] = useAtom(buyerDtlsGstinAtom);
  const [buyerDtlsPin] = useAtom(buyerDtlsPinAtom);
  const [buyerDtlsTrdNm] = useAtom(buyerDtlsTrdNmAtom);
  const [buyerDtlsAddr1] = useAtom(buyerDtlsAddr1Atom);
  const [buyerDtlsAddr2] = useAtom(buyerDtlsAddr2Atom);
  const [buyerDtlsLoc] = useAtom(buyerDtlsLocAtom);
  const [buyerDtlsPos] = useAtom(buyerDtlsPosAtom);
  const [sellerDtlsStcd] = useAtom(sellerDtlsStcdAtom);
  const [sellerDtlsLglNm] = useAtom(sellerDtlsLglNmAtom);
  const [sellerDtlsGstin] = useAtom(sellerDtlsGstinAtom);
  const [sellerDtlsPin] = useAtom(sellerDtlsPinAtom);
  const [sellerDtlsTrdNm] = useAtom(sellerDtlsTrdNmAtom);
  const [sellerDtlsAddr1] = useAtom(sellerDtlsAddr1Atom);
  const [sellerDtlsAddr2] = useAtom(sellerDtlsAddr2Atom);
  const [sellerDtlsLoc] = useAtom(sellerDtlsLocAtom);
  const [precDocDtlsInvNo] = useAtom(PrecDocDtlsInvNoAtom);
  const [precDocDtlsInvDt] = useAtom(PrecDocDtlsInvDtAtom);
  const [companyName] = useAtom(companyNameAtom);
  const [weightShortage] = useAtom(weightShortageAtom);
  const [trashExp] = useAtom(trashExpAtom);
  const [sampleTesting] = useAtom(sampleTestingAtom);
  const [cashDiscount] = useAtom(cashDiscountAtom);
  const [tradeDiscount] = useAtom(tradeDiscountAtom);
  const [qualityAllowance] = useAtom(qualityAllowanceAtom);
  const [otherCostNameField] = useAtom(otherCostNameFieldAtom);
  const [otherCostValueField] = useAtom(otherCostValueFieldAtom);
  const [note] = useAtom(noteAtom);

  const newDebitCreditNoteRestData = {
    valDtlsAssValue: valDtlsAssValue ? parseFloat(valDtlsAssValue) : null,
    valDtlsCgst: valDtlsCgst ? parseFloat(valDtlsCgst) : null,
    valDtlsSgst: valDtlsSgst ? parseFloat(valDtlsSgst) : null,
    valDtlsIgst: valDtlsIgst ? parseFloat(valDtlsIgst) : null,
    valDtlsCesVal: valDtlsCesVal ? parseFloat(valDtlsCesVal) : null,
    valDtlsStCesVal: valDtlsStCesVal ? parseFloat(valDtlsStCesVal) : null,
    valDtlsDiscount: valDtlsDiscount ? parseFloat(valDtlsDiscount) : null,
    valDtlsOthChrg: valDtlsOthChrg ? parseFloat(valDtlsOthChrg) : null,
    valDtlsRndOffAmt: valDtlsRndOffAmt ? parseFloat(valDtlsRndOffAmt) : null,
    valDtlsTotInvVal: valDtlsTotInvVal ? parseFloat(valDtlsTotInvVal) : null,
    valDtlsTotInvValFc: valDtlsTotInvValFc ? parseFloat(valDtlsTotInvValFc) : null,
    itemListSlNo,
    itemListPrdDesc,
    itemListIsServc,
    itemListHsnCd,
    itemListQty,
    itemListFreeQty,
    itemListUnit,
    itemListUnitPrice: itemListUnitPrice ? parseFloat(itemListUnitPrice) : null,
    itemListTotAmt: itemListTotAmt ? parseFloat(itemListTotAmt) : null,
    itemListDiscount: itemListDiscount ? parseFloat(itemListDiscount) : null,
    itemListAssAmt: itemListAssAmt ? parseFloat(itemListAssAmt) : null,
    itemListGstRt: itemListGstRt ? parseFloat(itemListGstRt) : null,
    itemListIgstAmt: itemListIgstAmt ? parseFloat(itemListIgstAmt) : null,
    itemListCgstAmt: itemListCgstAmt ? parseFloat(itemListCgstAmt) : null,
    itemListSgstAmt: itemListSgstAmt ? parseFloat(itemListSgstAmt) : null,
    itemListCesRt: itemListCesRt ? parseFloat(itemListCesRt) : null,
    itemListCesAmt: itemListCesAmt ? parseFloat(itemListCesAmt) : null,
    itemListCesNonAdvlAmt: itemListCesNonAdvlAmt ? parseFloat(itemListCesNonAdvlAmt) : null,
    itemListStateCesRt: itemListStateCesRt ? parseFloat(itemListStateCesRt) : null,
    itemListStateCesAmt: itemListStateCesAmt ? parseFloat(itemListStateCesAmt) : null,
    itemListStateCesNonAdvlAmt: itemListStateCesNonAdvlAmt ? parseFloat(itemListStateCesNonAdvlAmt) : null,
    itemListOthChrg: itemListOthChrg ? parseFloat(itemListOthChrg) : null,
    itemListTotItemVal: itemListTotItemVal ? parseFloat(itemListTotItemVal) : null,
    tranDtlsRegRev,
    tranDtlsEcmGstin,
    tranDtlsSupTyp,
    tranDtlsTaxSch,
    docDtlsDt,
    docDtlsNo,
    docDtlsTyp,
    version,
    buyerDtlsStcd,
    buyerDtlsLglNm,
    buyerDtlsGstin,
    buyerDtlsPin,
    buyerDtlsTrdNm,
    buyerDtlsAddr1,
    buyerDtlsAddr2,
    buyerDtlsLoc,
    buyerDtlsPos,
    sellerDtlsStcd,
    sellerDtlsLglNm,
    sellerDtlsGstin,
    sellerDtlsPin,
    sellerDtlsTrdNm,
    sellerDtlsAddr1,
    sellerDtlsAddr2,
    sellerDtlsLoc,
    precDocDtlsInvNo,
    precDocDtlsInvDt,
    companyName,
    weightShortage: weightShortage ? parseFloat(weightShortage) : null,
    trashExp: trashExp ? parseFloat(trashExp) : null,
    sampleTesting: sampleTesting ? parseFloat(sampleTesting) : null,
    cashDiscount: cashDiscount ? parseFloat(cashDiscount) : null,
    tradeDiscount: tradeDiscount ? parseFloat(tradeDiscount) : null,
    qualityAllowance: qualityAllowance ? parseFloat(qualityAllowance) : null,
    otherCostNameField,
    otherCostValueField: otherCostValueField ? parseFloat(otherCostValueField) : null,
    note
  };

  return [newDebitCreditNoteRestData];
}

export default useCreateDebitCreditNote;
