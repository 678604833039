import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

function MenuToggleButton() {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="back-arrow"
      sx={{ mr: 2 }}
    >
      <MenuIcon />
    </IconButton>
  );
}

export default MenuToggleButton;
