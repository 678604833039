import { useAtom } from "jotai";
import { docDtlsNoAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function DocDtlsNo() {
    const [docDtlsNo, setDocDtlsNo] = useAtom(docDtlsNoAtom);

    return <TextField
    id="outlined-basic"
    label="Document Number"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={docDtlsNo}
    onChange={(e) => {
        setDocDtlsNo(e.target.value);
    }}
  />;
};

export default DocDtlsNo;