import { Grid } from "@mui/material";
import EmailTo from "../Remarks&Email/EmailTo";
import Remarks from "../Remarks&Email/Remarks";

function RemarksandEmail() {
  return (
    <Grid container>
      <hr className="full-width" />

      <Grid xs={12} sm={12} md={6} padding="20px" className="remarks">
        <Remarks />
      </Grid>

      <Grid xs={12} sm={12} md={6} padding="20px" className="email-to">
        <EmailTo />
      </Grid>
    </Grid>
  );
}

export default RemarksandEmail;
