import { useAtom } from "jotai";
import { itemListPrdDescAtom } from "../../states/DebitCreditNotesState";
import Select from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

function ItemListPrdDesc() {
    const [itemListPrdDesc, setItemListPrdDesc] = useAtom(itemListPrdDescAtom);

    return (
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Prod. Desc.</InputLabel>
    <Select
    id="demo-simple-select"
    label="Type"
    labelId="demo-simple-select-label"
    variant="outlined"
    value={itemListPrdDesc}
    fullWidth
    onChange={(e) => {
      setItemListPrdDesc(e.target.value);
    }}
  >
    <MenuItem value="F.P. Cotton bales">F.P. Cotton bales</MenuItem>
    <MenuItem value="Courier Services">Courier Services</MenuItem>
  </Select></FormControl>);
};

export default ItemListPrdDesc;