import { atom } from "jotai";
import dayjs, { Dayjs } from "dayjs";
import { Company } from "../../../models";
import { DebitCreditNoteType } from "../models";

// Value Details (ValDtls)
export const valDtlsAssValueAtom = atom<string>((get) => {
  const weightShortage = parseFloat(get(weightShortageAtom));
  const trashExp = parseFloat(get(trashExpAtom));
  const sampleTesting = parseFloat(get(sampleTestingAtom));
  const cashDiscount = parseFloat(get(cashDiscountAtom));
  const tradeDiscount = parseFloat(get(tradeDiscountAtom));
  const qualityAllowance = parseFloat(get(qualityAllowanceAtom));
  const otherCostValueField = parseFloat(get(otherCostValueFieldAtom));
  return `${weightShortage + trashExp + sampleTesting + cashDiscount + tradeDiscount + qualityAllowance + otherCostValueField}`;
});
export const valDtlsCgstAtom = atom<string>((get) => {
  const buyerStateCode = get(buyerDtlsStcdAtom);
  const sellerStateCode = get(sellerDtlsStcdAtom);
  if(buyerStateCode !== sellerStateCode) {
    return "0";
  } else {
    const valDtlsAssAmt = parseFloat(get(valDtlsAssValueAtom));
    const itemListGstRt = parseFloat(get(itemListGstRtAtom));
    return `${(Math.round(((valDtlsAssAmt * itemListGstRt) / 200)*100)/100)}`;
  }
});
export const valDtlsSgstAtom = atom<string>((get) => {
  const buyerStateCode = get(buyerDtlsStcdAtom);
  const sellerStateCode = get(sellerDtlsStcdAtom);
  if(buyerStateCode !== sellerStateCode) {
    return "0";
  } else {
    const valDtlsAssAmt = parseFloat(get(valDtlsAssValueAtom));
    const itemListGstRt = parseFloat(get(itemListGstRtAtom));
    return `${(Math.round(((valDtlsAssAmt * itemListGstRt) / 200)*100)/100)}`;
  }
});
export const valDtlsIgstAtom = atom<string>((get) => {
  const buyerStateCode = get(buyerDtlsStcdAtom);
  const sellerStateCode = get(sellerDtlsStcdAtom);
  if(buyerStateCode === sellerStateCode) {
    return "0";
  } else {
    const itemListAssAmt = parseFloat(get(itemListAssAmtAtom));
    const itemListGstRt = parseFloat(get(itemListGstRtAtom));
    return `${(Math.round(((itemListAssAmt * itemListGstRt) / 100)*100)/100)}`;
  }
});
export const valDtlsCesValAtom = atom<string>("0");
export const valDtlsStCesValAtom = atom<string>("0");
export const valDtlsDiscountAtom = atom<string>("0");
export const valDtlsOthChrgAtom = atom<string>("0");
export const valDtlsRndOffAmtAtom = atom<string>((get) => {
  const totInvVal = parseFloat(get(itemListTotItemValAtom));
  return `${(Math.round(totInvVal) - totInvVal).toFixed(2)}`;
});
export const valDtlsTotInvValAtom = atom<string>((get) => {
  const preTaxValue = parseFloat(get(valDtlsAssValueAtom));
  const cgst = parseFloat(get(valDtlsCgstAtom));
  const sgst = parseFloat(get(valDtlsSgstAtom));
  const igst = parseFloat(get(valDtlsIgstAtom));
  const roundOff = parseFloat(get(valDtlsRndOffAmtAtom));

  return (preTaxValue + cgst + sgst + igst + roundOff).toFixed(2);
});
export const valDtlsTotInvValFcAtom = atom<string | null>(null);

// Item List (ItemList)
export const itemListSlNoAtom = atom<string>("1");
export const itemListPrdDescAtom = atom<string>("F.P. Cotton bales");
export const itemListIsServcAtom = atom<string>("N");
export const itemListHsnCdAtom = atom<string>("");
export const itemListQtyAtom = atom<number>(1);
export const itemListFreeQtyAtom = atom<number>(0);
export const itemListUnitAtom = atom<string>("KGS");
export const itemListUnitPriceAtom = atom<string>((get) => {
  const UnitPrice = get(itemListAssAmtAtom);
  return `${UnitPrice}`;
});
export const itemListTotAmtAtom = atom<string>((get) => {
  const weightShortage = parseFloat(get(weightShortageAtom));
  const trashExp = parseFloat(get(trashExpAtom));
  const sampleTesting = parseFloat(get(sampleTestingAtom));
  const cashDiscount = parseFloat(get(cashDiscountAtom));
  const tradeDiscount = parseFloat(get(tradeDiscountAtom));
  const qualityAllowance = parseFloat(get(qualityAllowanceAtom));
  const otherCostValueField = parseFloat(get(otherCostValueFieldAtom));
  const totAmt = weightShortage + trashExp + sampleTesting + cashDiscount + tradeDiscount + qualityAllowance + otherCostValueField;
  return `${totAmt}`;
});
export const itemListDiscountAtom = atom<string>("0");
export const itemListAssAmtAtom = atom<string>((get) => {
  const weightShortage = parseFloat(get(weightShortageAtom));
  const trashExp = parseFloat(get(trashExpAtom));
  const sampleTesting = parseFloat(get(sampleTestingAtom));
  const cashDiscount = parseFloat(get(cashDiscountAtom));
  const tradeDiscount = parseFloat(get(tradeDiscountAtom));
  const qualityAllowance = parseFloat(get(qualityAllowanceAtom));
  const otherCostValueField = parseFloat(get(otherCostValueFieldAtom));
  const assAmt = weightShortage + trashExp + sampleTesting + cashDiscount + tradeDiscount + qualityAllowance + otherCostValueField;
  return `${assAmt}`;
});
export const itemListGstRtAtom = atom<string>("5.0");
export const itemListIgstAmtAtom = atom<string>((get) => {
  const buyerStateCode = get(buyerDtlsStcdAtom);
  const sellerStateCode = get(sellerDtlsStcdAtom);
  if(buyerStateCode === sellerStateCode) {
    return "0";
  } else {
    const itemListAssAmt = parseFloat(get(itemListAssAmtAtom));
    const itemListGstRt = parseFloat(get(itemListGstRtAtom));
    return `${(Math.round(((itemListAssAmt * itemListGstRt) / 100)*100)/100)}`;
  }
});
export const itemListCgstAmtAtom = atom<string>((get) => {
  const buyerStateCode = get(buyerDtlsStcdAtom);
  const sellerStateCode = get(sellerDtlsStcdAtom);
  if(buyerStateCode !== sellerStateCode) {
    return "0";
  } else {
    const itemListAssAmt = parseFloat(get(itemListAssAmtAtom));
    const itemListGstRt = parseFloat(get(itemListGstRtAtom));
    return `${(Math.round(((itemListAssAmt * itemListGstRt) / 200)*100)/100)}`;
  }
});
export const itemListSgstAmtAtom = atom<string>((get) => {
  const buyerStateCode = get(buyerDtlsStcdAtom);
  const sellerStateCode = get(sellerDtlsStcdAtom);
  if(buyerStateCode !== sellerStateCode) {
    return "0";
  } else {
    const itemListAssAmt = parseFloat(get(itemListAssAmtAtom));
    const itemListGstRt = parseFloat(get(itemListGstRtAtom));
    return `${(Math.round(((itemListAssAmt * itemListGstRt) / 200)*100)/100)}`;
  }
});
export const itemListCesRtAtom = atom<string>("0");
export const itemListCesAmtAtom = atom<string>("0");
export const itemListCesNonAdvlAmtAtom = atom<string>("0");
export const itemListStateCesRtAtom = atom<string>("0");
export const itemListStateCesAmtAtom = atom<string>("0");
export const itemListStateCesNonAdvlAmtAtom = atom<string>("0");
export const itemListOthChrgAtom = atom<string>("0");
export const itemListTotItemValAtom = atom<string>((get) => {
  const preTaxValue = parseFloat(get(itemListAssAmtAtom));
  const cgst = parseFloat(get(itemListCgstAmtAtom));
  const sgst = parseFloat(get(itemListSgstAmtAtom));
  const igst = parseFloat(get(itemListIgstAmtAtom));
  return (preTaxValue + cgst + sgst + igst).toFixed(2);
});

// Transaction Details (TranDtls)
export const tranDtlsRegRevAtom = atom<string>("N");
export const tranDtlsEcmGstinAtom = atom<string | null>(null);
export const tranDtlsSupTypAtom = atom<string>("B2B");
export const tranDtlsTaxSchAtom = atom<string>("GST");
export const tranDtlsIgstOnIntraAtom = atom<number | null>(null);

// Document Details (DocDtls)
export const docDtlsDtAtom = atom<Dayjs | null>(dayjs(new Date()));
export const docDtlsNoAtom = atom<string>("");
export const docDtlsTypAtom = atom<DebitCreditNoteType>(DebitCreditNoteType.EMPTY);

export const versionAtom = atom<string>("1.1");

// Buyer Details (BuyerDtls)
export const buyerDtlsStcdAtom = atom<string>("");
export const buyerDtlsLglNmAtom = atom<string>("");
export const buyerDtlsGstinAtom = atom<string>("");
export const buyerDtlsPinAtom = atom<number>(0);
export const buyerDtlsTrdNmAtom = atom<string>("");
export const buyerDtlsAddr1Atom = atom<string>("");
export const buyerDtlsAddr2Atom = atom<string | null>(null);
export const buyerDtlsLocAtom = atom<string>("");
export const buyerDtlsPosAtom = atom<string>("");

//seller details (SellerDtls)
export const sellerDtlsStcdAtom = atom<string>("");
export const sellerDtlsLglNmAtom = atom<string>("");
export const sellerDtlsGstinAtom = atom<string>("");
export const sellerDtlsPinAtom = atom<number>(0);
export const sellerDtlsTrdNmAtom = atom<string>("");
export const sellerDtlsAddr1Atom = atom<string>("");
export const sellerDtlsAddr2Atom = atom<string>("");
export const sellerDtlsLocAtom = atom<string>("");

// Reference Details (RefDtls)
export const PrecDocDtlsInvNoAtom = atom<string>("");
export const PrecDocDtlsInvDtAtom = atom<Dayjs | null>(dayjs(new Date()));

export const companyNameAtom = atom<Company>(Company.EMPTY);

export const weightShortageAtom = atom<string>("0");
export const trashExpAtom = atom<string>("0");
export const sampleTestingAtom = atom<string>("0");
export const cashDiscountAtom = atom<string>("0");
export const tradeDiscountAtom = atom<string>("0");
export const qualityAllowanceAtom = atom<string>("0");
export const otherCostNameFieldAtom = atom<string>("");
export const otherCostValueFieldAtom = atom<string>("0");
export const noteAtom = atom<string>("");

// ---------------------------------------------------------------------------------------------

export const creditNoteListAtom = atom<any[]>([]);
export const debitCreditNoteListAtom = atom<any[]>([]);
export const showDialogBoxAtom = atom<boolean>(false);
export const selectedDebitCreditNoteAtom = atom<any | null>(null);

// Reset Form

export const resetDebitCreditNoteFormAtom = atom(null, (_, set) => {

  set(itemListHsnCdAtom, "");

  set(docDtlsDtAtom, dayjs(new Date()));
  set(docDtlsNoAtom, "");
  set(docDtlsTypAtom, DebitCreditNoteType.EMPTY);

  set(buyerDtlsStcdAtom, "");
  set(buyerDtlsLglNmAtom, "");
  set(buyerDtlsGstinAtom, "");
  set(buyerDtlsPinAtom, 0);
  set(buyerDtlsTrdNmAtom, "");
  set(buyerDtlsAddr1Atom, "");
  set(buyerDtlsAddr2Atom, null);
  set(buyerDtlsLocAtom, "");
  set(buyerDtlsPosAtom, "");

  set(sellerDtlsStcdAtom, "");
  set(sellerDtlsLglNmAtom, "");
  set(sellerDtlsGstinAtom, "");
  set(sellerDtlsPinAtom, 0);
  set(sellerDtlsTrdNmAtom, "");
  set(sellerDtlsAddr1Atom, "");
  set(sellerDtlsAddr2Atom, "");
  set(sellerDtlsLocAtom, "");

  set(PrecDocDtlsInvNoAtom, "");
  set(PrecDocDtlsInvDtAtom, dayjs(new Date()));

  set(companyNameAtom, Company.EMPTY);

  set(weightShortageAtom, "0");
  set(trashExpAtom, "0");
  set(sampleTestingAtom, "0");
  set(cashDiscountAtom, "0");
  set(tradeDiscountAtom, "0");
  set(qualityAllowanceAtom, "0");
  set(otherCostNameFieldAtom, "");
  set(otherCostValueFieldAtom, "0");
  set(noteAtom, "");
});
