import { Grid } from "@mui/material";
import PickUpAddress from "../PickUpDetails/PickUpAddress";
import PickUpName from "../PickUpDetails/PickUpName";
import PickUpPinCode from "../PickUpDetails/PickUpPinCode";
import PickUpPlace from "../PickUpDetails/PickUpPlace";
import PickUpState from "../PickUpDetails/PickUpState";

function PickUpDetails() {
  return (
    <Grid container>
      <hr className="full-width" />

      <Grid xs={12} sm={6} md={6} padding="20px" className="pick-up-name">
        <PickUpName />
      </Grid>

      <Grid xs={12} sm={6} md={6} padding="20px" className="pick-up-address">
        <PickUpAddress />
      </Grid>

      <Grid xs={12} sm={6} md={4} padding="20px" className="pick-up-place">
        <PickUpPlace />
      </Grid>

      <Grid xs={12} sm={6} md={4} padding="20px" className="pick-up-pin-code">
        <PickUpPinCode />
      </Grid>

      <Grid xs={12} sm={6} md={4} padding="20px" className="pick-up-state">
        <PickUpState />
      </Grid>
    </Grid>
  );
}

export default PickUpDetails;
