import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useAtom } from 'jotai';
import { selectedFailedInvoiceAtom, showDialogBoxAtom } from '../states/invoicesState';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FailedDialogBox() {
    const [showDialogBox, setShowDialogBox] = useAtom(showDialogBoxAtom);
    const [selectedFailedInvoice, ] = useAtom(selectedFailedInvoiceAtom);
  
    const handleClose = () => {
      setShowDialogBox(false);
    };

    return (
        <Dialog
        fullScreen
        open={showDialogBox}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sound
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
                handleClose();
            }}>
              Edit
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Company" secondary={selectedFailedInvoice?.companyName} />
          </ListItem>
          <Divider />
        </List>
      </Dialog>
    );
}