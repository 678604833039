import { useAtom } from "jotai";
import { sellerDtlsTrdNmAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsTrdNm() {
    const [sellerDtlsTrdNm, setSellerDtlsTrdNm] = useAtom(sellerDtlsTrdNmAtom);

    return <TextField
    id="outlined-basic"
    label="TrdNm"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsTrdNm}
    onChange={(e) => {
        setSellerDtlsTrdNm(e.target.value);
    }}
  />;
};

export default SellerDtlsTrdNm;