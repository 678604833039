import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { rateAtom } from "../../states/invoicesState";

function Rate() {
    const [rate, setRate] = useAtom(rateAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Rate"
            variant="outlined"
            autoComplete="off"
            fullWidth
            type="number"
            value={rate}
            onChange={(e) => {
              setRate(e.target.value);
            }}
          />
    );
}

export default Rate;