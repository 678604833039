import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import { handleLoginWithGoogle } from "../../authentication/helpers";

const LogInWithGoogle = () => {
  return (
    <Button
      type="button"
      color="success"
      variant="contained"
      onClick={handleLoginWithGoogle}
      style={{ margin: "10px" }}
      startIcon={<GoogleIcon />}
    >
      Login with Google
    </Button>
  );
};

export default LogInWithGoogle;
