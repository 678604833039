import { Grid, Typography } from "@mui/material";
import CompanyName from "../DocumentDetails/CompanyName";
import DocDtlsDt from "../DocumentDetails/Dt";
import DocDtlsNo from "../DocumentDetails/No";
import DocDtlsTyp from "../DocumentDetails/Typ";
import PrecDocDtlsInvDt from "../DocumentDetails/precDocDtlsInvDt";
import PrecDocDtlsInvNo from "../DocumentDetails/precDocDtlsInvNo";

function DebitCreditNoteDetails() {
  return (
    <Grid container>
      <Typography>Document Details</Typography>
      <hr className="full-width" />
      <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
        <DocDtlsDt />
      </Grid>
      <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
        <DocDtlsNo />
      </Grid>
      <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
        <DocDtlsTyp />
      </Grid>
      <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
        <CompanyName />
      </Grid>
      <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
        <PrecDocDtlsInvDt />
      </Grid>
      <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
        <PrecDocDtlsInvNo />
      </Grid>
    </Grid>
  );
}

export default DebitCreditNoteDetails;
