import { useAtom } from "jotai";
import { sellerDtlsLocAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsLoc() {
    const [sellerDtlsLoc, setSellerDtlsLoc] = useAtom(sellerDtlsLocAtom);

    return <TextField
    id="outlined-basic"
    label="Loc"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsLoc}
    onChange={(e) => {
        setSellerDtlsLoc(e.target.value);
    }}
  />;
};

export default SellerDtlsLoc;