import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { handleFetchDebitCreditNotes } from "../helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Unstable_Grid2";
import {
  debitCreditNoteListAtom,
  selectedDebitCreditNoteAtom,
  showDialogBoxAtom,
} from "../states/DebitCreditNotesState";
import { useAtom } from "jotai";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import DebitCreditNoteDialogBox from "./DebitCreditNoteDialogBox";
import { DebitCreditNoteType } from "../models";
import { FC } from "react";

type DebitCreditNoteListProps = {
  docType: DebitCreditNoteType;
}

const DebitCreditNoteList:FC<DebitCreditNoteListProps> = ({docType}) => {
    const [debitCreditNoteList, setDebitCreditNoteList] = useAtom(debitCreditNoteListAtom);
    const [, setSelectedDebitCreditNote] = useAtom(selectedDebitCreditNoteAtom);
    const [, setShowDialogBox] = useAtom(showDialogBoxAtom);
    const [fetching, setFetching] = useState(false);
    const [lastDebitCreditNoteSnapshot, setLastDebitCreditNoteSnapshot] = useState(null);
    const [hasMoreData, setHasMoreData] = useState(true);

    useEffect(() => {
        (async () => {
            const [DebitCreditNoteData, lastSnapshot] = await handleFetchDebitCreditNotes(docType);
            setDebitCreditNoteList(DebitCreditNoteData);
            setLastDebitCreditNoteSnapshot(lastSnapshot);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      function handleSingleClick(debitNote: any) {
        setSelectedDebitCreditNote(debitNote);
        setShowDialogBox(true);
      }

      const fetchMoreDebitCreditNoteData = async () => {
        setFetching(true);
        const [DebitCreditNoteData, lastSnapshot] = await handleFetchDebitCreditNotes(
          docType, lastDebitCreditNoteSnapshot
        );
        if (DebitCreditNoteData.length < 25) {
          setHasMoreData(false);
        }
        setDebitCreditNoteList([...debitCreditNoteList, ...DebitCreditNoteData]);
        setLastDebitCreditNoteSnapshot(lastSnapshot);
        setFetching(false);
      };

      const convertToDDMMYYYY = (isoString: string) => {
        const date = new Date(isoString);
        return `${convertNumberTo2Digits(date.getDate())}/${convertNumberTo2Digits(
          date.getMonth() + 1
        )}/${convertNumberTo2Digits(date.getFullYear())}`;
      };
    
      // const convertToHHMM = (isoString: string) => {
      //   const date = new Date(isoString);
      //   return `${convertNumberTo2Digits(date.getHours())}:${convertNumberTo2Digits(
      //     date.getMinutes()
      //   )}`;
      // }
    
      const convertNumberTo2Digits = (number: number) => {
        if (number > 9) {
          return `${number}`;
        }
    
        return `0${number}`;
      };
    
      // const ackDate = (i: number) => {
      //   const debitNote = debitNoteList[i];
      //   if (debitNote?.ackDt) {
      //     return (convertToDDMMYYYY(debitNote.ackDt));
      //   }
      //   return null;
      // };
    
      // const ackTime = (i: number) => {
      //   const debitNote = debitNoteList[i];
      //   if (debitNote?.ackDt) {
      //     return (convertToHHMM(debitNote.ackDt));
      //   }
      //   return null;
      // };


    return (
        <List
        id="debit-note-list"
        style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
      >
        <InfiniteScroll
          dataLength={debitCreditNoteList.length}
          next={() => {
            if (!fetching) {
              fetchMoreDebitCreditNoteData();
            }
          }}
          hasMore={hasMoreData}
          scrollableTarget="debit-note-list"
          loader={
            <div>
              <LinearProgress />
            </div>
          }
          endMessage={<p>No more data to load.</p>}
        >
          {debitCreditNoteList.map((debitNote: any, index) => [
            <ListItemButton
              key={"debit-note-list-item-" + index}
              onClick={() => {
                handleSingleClick(debitNote);
              }}
            >
              <Grid container width="100%">
                {/* <Grid xs={12} sm={6} md={2} className="debit-note-ack-date">
                  {ackDate(index)}
                </Grid> */}
                <Grid xs={12} sm={6} md={4} className="credit-note-ack-date">
                {convertToDDMMYYYY(debitNote?.restData?.docDtlsDt)}
                </Grid>
                <Grid xs={12} sm={6} md={4} className="credit-note-ack-date">
                {debitNote?.restData?.buyerDtlsLglNm}
                </Grid>
                {/* <Grid xs={12} sm={6} md={2} className="debit-note-ack-time">
                  {ackTime(index)}
                </Grid>
                
                <Grid xs={12} sm={6} md={3} className="debit-note-ack-date">
                  {debitNote.ackNo}
                </Grid> */}
                {/* <Grid xs={12} sm={6} md={3} className="debit-note-ack-date">
                  {debitNote?.restData?.companyName}
                </Grid> */}
                <Grid xs={12} sm={6} md={4} className="debit-note-ack-date">
                  {debitNote.restData?.docDtlsNo}
                </Grid>
              </Grid>
            </ListItemButton>,
            <Divider
              key={"debit-note-list-item-divider-" + index}
              variant="middle"
              component="li"
            />,
          ])}
        </InfiniteScroll>
        <DebitCreditNoteDialogBox />
      </List>
    )
}

export default DebitCreditNoteList;