import { useAtom } from "jotai";
import { PrecDocDtlsInvNoAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function PrecDocDtlsInvNo() {
    const [precDocDtlsInvNo, setPrecDocDtlsInvNo] = useAtom(PrecDocDtlsInvNoAtom);

    return <TextField
    id="outlined-basic"
    label="Invoice Number"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={precDocDtlsInvNo}
    onChange={(e) => {
        setPrecDocDtlsInvNo(e.target.value);
    }}
  />;
};

export default PrecDocDtlsInvNo;