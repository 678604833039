import { useAtom } from "jotai";
import { sellerDtlsPinAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsPin() {
    const [sellerDtlsPin, setSellerDtlsPin] = useAtom(sellerDtlsPinAtom);

    return <TextField
    id="outlined-basic"
    label="Pin"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsPin}
    onChange={(e) => {
      if(e.target.value) {
        setSellerDtlsPin(parseFloat(e.target.value));
      } else {
        setSellerDtlsPin(0);
      }
    }}
  />;
};

export default SellerDtlsPin;