import { useAtom } from "jotai";
import { itemListGstRtAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function ItemListGstRt() {
    const [itemListGstRt, setItemListGstRt] = useAtom(itemListGstRtAtom);

    return <TextField
    id="outlined-basic"
    label="GstRt"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={itemListGstRt}
    onChange={(e) => {
        setItemListGstRt(e.target.value);
    }}
  />;
};

export default ItemListGstRt;