import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { deliveryExpenseAtom } from "../../states/invoicesState";

function DeliveryExpense() {
    const [deliveryExpense, setdeliveryExpense] = useAtom(deliveryExpenseAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Delivery Exp"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={deliveryExpense}
            onChange={(e) => {
              setdeliveryExpense(e.target.value);
            }}
          />
    );

}

export default DeliveryExpense;