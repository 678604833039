import { useAtom } from "jotai";
import { sellerDtlsAddr1Atom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsAddr1() {
    const [sellerDtlsAddr1, setSellerDtlsAddr1] = useAtom(sellerDtlsAddr1Atom);

    return <TextField
    id="outlined-basic"
    label="Addr1"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsAddr1}
    onChange={(e) => {
        setSellerDtlsAddr1(e.target.value);
    }}
  />;
};

export default SellerDtlsAddr1;