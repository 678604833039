export type DraftInvoiceData = {
  id: number;
  invoiceDate: string;
  contractNumber: string;
  invoiceNumber: string;
  documentNumber: string;
  companyName: string;
  buyerName: string;
  material: string;
  station: string;
  pressNumber1: string;
  pressNumber2: string;
  pressNumber3: string;
  pressNumber4: string;
  pressNumber5: string;
  pressNumber6: string;
  goodsWeight: string;
  tare: string;
  quantity: string;
  deliveryExpense: string;
  rate: string;
  tcs: string;
  allowance: string;
  insurance: string;
  finalAmount: string;
  transporter: string;
  truckNumber: string;
  builtyNumber: string;
  builtyDate: string;
  pickUpName: string;
  pickUpAddress: string;
  pickUpPlace: string;
  pickUpPinCode: string;
  pickUpState: string;
  dropName: string;
  dropAddress: string;
  dropPlace: string;
  dropPinCode: string;
  dropState: string;
  remarks: string;
  emailTo: string;
  buyerGSTIN: string;
  buyerAddress: string;
  buyerState: string;
  buyerPinCode: string;
  buyerPlace: string;
  gstState: string;
  hsnCode: string;
  quality: string;
  dueDate: string;
};

export type CompletedInvoiceData = {
    id: number;
    invoiceDate: string;
    contractNumber: string;
    invoiceNumber: string;
    documentNumber: string;
    companyName: string;
    buyerName: string;
    material: string;
    station: string;
    pressNumber1: string;
    pressNumber2: string;
    pressNumber3: string;
    pressNumber4: string;
    pressNumber5: string;
    pressNumber6: string;
    goodsWeight: string;
    tare: string;
    quantity: string;
    deliveryExpense: string;
    rate: string;
    tcs: string;
    allowance: string;
    insurance: string;
    finalAmount: string;
    transporter: string;
    truckNumber: string;
    builtyNumber: string;
    builtyDate: string;
    pickUpName: string;
    pickUpAddress: string;
    pickUpPlace: string;
    pickUpPinCode: string;
    pickUpState: string;
    dropName: string;
    dropAddress: string;
    dropPlace: string;
    dropPinCode: string;
    dropState: string;
    remarks: string;
    emailTo: string;
    buyerGSTIN: string;
    buyerAddress: string;
    buyerState: string;
    buyerPinCode: string;
    buyerPlace: string;
    gstState: string;
    hsnCode: string;
    quality: string;
    dueDate: string;
  };

export type FailedInvoiceData = {
  id: number;
  invoiceDate: string;
  contractNumber: string;
  invoiceNumber: string;
  documentNumber: string;
  companyName: string;
  buyerName: string;
  material: string;
  station: string;
  pressNumber1: string;
  pressNumber2: string;
  pressNumber3: string;
  pressNumber4: string;
  pressNumber5: string;
  pressNumber6: string;
  goodsWeight: string;
  tare: string;
  quantity: string;
  deliveryExpense: string;
  rate: string;
  tcs: string;
  allowance: string;
  insurance: string;
  finalAmount: string;
  transporter: string;
  truckNumber: string;
  builtyNumber: string;
  builtyDate: string;
  pickUpName: string;
  pickUpAddress: string;
  pickUpPlace: string;
  pickUpPinCode: string;
  pickUpState: string;
  dropName: string;
  dropAddress: string;
  dropPlace: string;
  dropPinCode: string;
  dropState: string;
  remarks: string;
  emailTo: string;
  buyerGSTIN: string;
  buyerAddress: string;
  buyerState: string;
  buyerPinCode: string;
  buyerPlace: string;
  gstState: string;
  hsnCode: string;
  quality: string;
  dueDate: string;
};

export enum DraftInvoiceFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
}