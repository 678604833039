import { useAtom } from "jotai";
import { cashDiscountAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function CashDiscount() {
    const [cashDiscount, setCashDiscount] = useAtom(cashDiscountAtom);

    return <TextField
    id="outlined-basic"
    label="Cash Discount"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={cashDiscount}
    onChange={(e) => {
        setCashDiscount(e.target.value);
    }}
  />;
};

export default CashDiscount;