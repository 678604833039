import { Grid, Typography } from "@mui/material";
import WeightShortage from "../InputDetails/WeightShortage";
import Note from "../InputDetails/Note";
import CashDiscount from "../InputDetails/CashDiscount";
import OtherCostNameField from "../InputDetails/OtherCostNameField";
import OtherCostValueField from "../InputDetails/OtherCostValueField";
import QualityAllowance from "../InputDetails/QualityAllowance";
import SampleTesting from "../InputDetails/SampleTesting";
import TradeDiscount from "../InputDetails/TradeDiscount";
import TrashExp from "../InputDetails/TrashExp";

function DebitCreditNoteDetails() {
  return (
    <Grid container>
      <Typography>Credit / Debit Note Details</Typography>
      <hr className="full-width" />
      <Grid xs={12} sm={6} md={3} padding="20px" className="weight-shortage">
        <WeightShortage />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="trash-exp">
        <TrashExp />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="sample-testing">
        <SampleTesting />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="quality-allowance">
        <QualityAllowance />
      </Grid>

      <Grid xs={12} sm={6} md={2.4} padding="20px" className="cash-discount">
        <CashDiscount />
      </Grid>
      <Grid xs={12} sm={6} md={2.4} padding="20px" className="trade-discount">
        <TradeDiscount />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={2.4}
        padding="20px"
        className="other-cost-name-field"
      >
        <OtherCostNameField />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={2.4}
        padding="20px"
        className="other-cost-value-field"
      >
        <OtherCostValueField />
      </Grid>
      <Grid xs={12} sm={6} md={2.4} padding="20px" className="note">
        <Note />
      </Grid>
    </Grid>
  );
}

export default DebitCreditNoteDetails;
