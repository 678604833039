import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pickUpPinCodeAtom } from "../../states/invoicesState";

function PickUpPinCode() {
    const [pickUpPinCode, setPickUpPinCode] = useAtom(pickUpPinCodeAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Pin Code"
            variant="outlined"
            autoComplete="off"
            fullWidth
            type="number"
            value={pickUpPinCode}
            onChange={(e) => {
              setPickUpPinCode(e.target.value);
            }}
          />
    );
}

export default PickUpPinCode;