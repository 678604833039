import { Grid } from "@mui/material";
import BuiltyDate from "../TransporterDetails/BuiltyDate";
import BuiltyNumber from "../TransporterDetails/BuiltyNumber";
import Transporter from "../TransporterDetails/Transporter";
import TruckNumber from "../TransporterDetails/TruckNumber";

function TransporterDetails() {
  return (
    <Grid container>
      <hr className="full-width" />

      <Grid xs={12} sm={6} md={5} padding="20px" className="transporter">
        <Transporter />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="truck-number">
        <TruckNumber />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="builty-number">
        <BuiltyNumber />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="builty-date">
        <BuiltyDate />
      </Grid>
    </Grid>
  );
}

export default TransporterDetails;
