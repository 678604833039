import { useAtom } from "jotai";
import {
  invoiceDateAtom,
  contractNumberAtom,
  invoiceNumberAtom,
  documentNumberAtom,
  companyAtom,
  buyerNameAtom,
  materialAtom,
  stationAtom,
  pressNumber1Atom,
  pressNumber2Atom,
  pressNumber3Atom,
  pressNumber4Atom,
  pressNumber5Atom,
  goodsWeightAtom,
  tareAtom,
  quantityAtom,
  deliveryExpenseAtom,
  rateAtom,
  TCSAtom,
  allowanceAtom,
  insuranceAtom,
  finalAmountAtom,
  transporterAtom,
  truckNumberAtom,
  builtyNumberAtom,
  builtyDateAtom,
  pickUpNameAtom,
  pickUpAddressAtom,
  pickUpPlaceAtom,
  pickUpPinCodeAtom,
  pickUpStateAtom,
  dropNameAtom,
  dropAddressAtom,
  dropPlaceAtom,
  dropPinCodeAtom,
  dropStateAtom,
  remarksAtom,
  emailToAtom,
  buyerGSTINAtom,
  buyerAddressAtom,
  buyerPlaceAtom,
  buyerStateAtom,
  buyerPinCodeAtom,
  gstStateAtom,
  hsnCodeAtom,
  qualityAtom,
  dueDateAtom
} from "../states/invoicesState";
import dayjs, { Dayjs } from "dayjs";

function useCopyInvoice() {
  const [, setInvoiceDate] = useAtom(invoiceDateAtom);
  const [, setContractNumber] = useAtom(contractNumberAtom);
  const [, setInvoiceNumber] = useAtom(invoiceNumberAtom);
  const [, setDocumentNumber] = useAtom(documentNumberAtom);
  const [, setCompany] = useAtom(companyAtom);
  const [, setBuyerName] = useAtom(buyerNameAtom);
  const [, setMaterial] = useAtom(materialAtom);
  const [, setStation] = useAtom(stationAtom);
  const [, setPressNumber1] = useAtom(pressNumber1Atom);
  const [, setPressNumber2] = useAtom(pressNumber2Atom);
  const [, setPressNumber3] = useAtom(pressNumber3Atom);
  const [, setPressNumber4] = useAtom(pressNumber4Atom);
  const [, setPressNumber5] = useAtom(pressNumber5Atom);
  const [, setGoodsWeight] = useAtom(goodsWeightAtom);
  const [, setTare] = useAtom(tareAtom);
  const [, setQuantity] = useAtom(quantityAtom);
  const [, setdeliveryExpense] = useAtom(deliveryExpenseAtom);
  const [, setRate] = useAtom(rateAtom);
  const [, setTcs] = useAtom(TCSAtom);
  const [, setAllowance] = useAtom(allowanceAtom);
  const [, setInsurance] = useAtom(insuranceAtom);
  const [, setFinalAmount] = useAtom(finalAmountAtom);
  const [, setTransporter] = useAtom(transporterAtom);
  const [, setTruckNumber] = useAtom(truckNumberAtom);
  const [, setBuiltyNumber] = useAtom(builtyNumberAtom);
  const [, setBuiltyDate] = useAtom(builtyDateAtom);
  const [, setPickUpName] = useAtom(pickUpNameAtom);
  const [, setPickUpAddress] = useAtom(pickUpAddressAtom);
  const [, setPickUpPlace] = useAtom(pickUpPlaceAtom);
  const [, setPickUpPinCode] = useAtom(pickUpPinCodeAtom);
  const [, setPickUpState] = useAtom(pickUpStateAtom);
  const [, setDropName] = useAtom(dropNameAtom);
  const [, setDropAddress] = useAtom(dropAddressAtom);
  const [, setDropPlace] = useAtom(dropPlaceAtom);
  const [, setDropPinCode] = useAtom(dropPinCodeAtom);
  const [, setDropState] = useAtom(dropStateAtom);
  const [, setRemarks] = useAtom(remarksAtom);
  const [, setEmailTo] = useAtom(emailToAtom);
  const [, setBuyerGSTIN] = useAtom(buyerGSTINAtom);
  const [, setBuyerAddress] = useAtom(buyerAddressAtom);
  const [, setBuyerPlace] = useAtom(buyerPlaceAtom);
  const [, setBuyerState] = useAtom(buyerStateAtom);
  const [, setBuyerPinCode] = useAtom(buyerPinCodeAtom);
  const [, setGstState] = useAtom(gstStateAtom);
  const [, setHsnCode] = useAtom(hsnCodeAtom);
  const [, setQuality] = useAtom(qualityAtom);
  const [, setDueDate] = useAtom(dueDateAtom);

  async function useCopiedContent() {
   await navigator.clipboard
      .readText()
      .then((copiedData) => {
        try {
          console.log("Pasted content: ", copiedData);
          const data = JSON.parse(copiedData);

           if (data?.invoiceDate) {
            const invoiceDate: Dayjs = dayjs(new Date(data.invoiceDate));
            setInvoiceDate(invoiceDate);
          }
          if (data?.contractNumber) {
            setContractNumber(data.contractNumber);
          }
          if (data?.invoiceNumber) {
            setInvoiceNumber(data.invoiceNumber);
          }
          if (data?.documentNumber) {
            setDocumentNumber(data.documentNumber);
          }
          if (data?.company) {
            setCompany(data.company);
          }
          if (data?.buyerName) {
            setBuyerName(data.buyerName);
          }
          if (data?.material) {
            setMaterial(data.material);
          }
          if (data?.station) {
            setStation(data.station);
          }
          if (data?.pressNumber1) {
            setPressNumber1(data.pressNumber1);
          }
          if (data?.pressNumber2) {
            setPressNumber2(data.pressNumber2);
          }
          if (data?.pressNumber3) {
            setPressNumber3(data.pressNumber3);
          }
          if (data?.pressNumber4) {
            setPressNumber4(data.pressNumber4);
          }
          if (data?.pressNumber5) {
            setPressNumber5(data.pressNumber5);
          }
          if (data?.goodsWeight) {
            setGoodsWeight(data.goodsWeight);
          }
          if (data?.tare) {
            setTare(data.tare);
          }
          if (data?.quantity) {
            setQuantity(data.quantity);
          }
          if (data?.deliveryExpense) {
            setdeliveryExpense(data.deliveryExpense);
          }
          if (data?.rate) {
            setRate(data.rate);
          }
          if (data?.tcs) {
            setTcs(data.tcs);
          }
          if (data?.allowance) {
            setAllowance(data.allowance);
          }
          if (data?.insurance) {
            setInsurance(data.insurance);
          }
          if (data?.finalAmount) {
            setFinalAmount(data.finalAmount);
          }
          if (data?.transporter) {
            setTransporter(data.transporter);
          }
          if (data?.truckNumber) {
            setTruckNumber(data.truckNumber);
          }
          if (data?.builtyNumber) {
            setBuiltyNumber(data.builtyNumber);
          }
          if (data?.builtyDate) {
            const builtyDate: Dayjs = dayjs(new Date(data.builtyDate));
            setBuiltyDate(builtyDate);
          }
          if (data?.pickUpName) {
            setPickUpName(data.pickUpName);
          }
          if (data?.pickUpAddress) {
            setPickUpAddress(data.pickUpAddress);
          }
          if (data?.pickUpPlace) {
            setPickUpPlace(data.pickUpPlace);
          }
          if (data?.pickUpPinCode) {
            setPickUpPinCode(data.pickUpPinCode);
          }
          if (data?.pickUpState) {
            setPickUpState(data.pickUpState);
          }
          if (data?.dropName) {
            setDropName(data.dropName);
          }
          if (data?.dropAddress) {
            setDropAddress(data.dropAddress);
          }
          if (data?.dropPlace) {
            setDropPlace(data.dropPlace);
          }
          if (data?.dropPinCode) {
            setDropPinCode(data.dropPinCode);
          }
          if (data?.dropState) {
            setDropState(data.dropState);
          }
          if (data?.remarks) {
            setRemarks(data.remarks);
          }
          if (data?.emailTo) {
            setEmailTo(data.emailTo);
          }
          if (data?.toGstin) {
            setBuyerGSTIN(data.toGstin);
          }
          if (data?.toAddress) {
            setBuyerAddress(data.toAddress);
          }
          if (data?.toPlace) {
            setBuyerPlace(data.toPlace);
          }
          if (data?.toState) {
            setBuyerState(data.toState);
          }
          if (data?.toPin) {
            setBuyerPinCode(data.toPin);
          }
          if (data?.GSTstate) {
            setGstState(data.GSTstate);
          }
          if (data?.hsnCode) {
            setHsnCode(data.hsnCode);
          }
          if (data?.quality) {
            setQuality(data.quality);
          }
          if (data?.dueDate) {
            const dueDate: Dayjs = dayjs(new Date(data.dueDate));
            setDueDate(dueDate);
          }
        } catch (e) {
          alert(e);
        }
      })
      .catch((err) => {
        console.error("Failed to read clipboard contents: ", err);
      });
  }

  return {
    useCopiedContent,
  };
}

export default useCopyInvoice;
