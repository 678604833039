import { useAtom } from "jotai";
import { tradeDiscountAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function TradeDiscount() {
    const [tradeDiscount, setTradeDiscount] = useAtom(tradeDiscountAtom);

    return <TextField
    id="outlined-basic"
    label="Trade Discount"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={tradeDiscount}
    onChange={(e) => {
        setTradeDiscount(e.target.value);
    }}
  />;
};

export default TradeDiscount;