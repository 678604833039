import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pressNumber5Atom } from "../../states/invoicesState";

function PressNumber5() {
    const [pressNumber5, setPressNumber5] = useAtom(pressNumber5Atom);

    return (
        <TextField
            id="outlined-basic"
            label="Press No. 5"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pressNumber5}
            onChange={(e) => {
              setPressNumber5(e.target.value);
            }}
          />
    );
}

export default PressNumber5;