import { useAtom } from "jotai";
import { invoiceNumberAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function InvoiceNumber() {
    const [invoiceNumber, setInvoiceNumber] = useAtom(invoiceNumberAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Invoice No."
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={invoiceNumber}
            onChange={(e) => {
              setInvoiceNumber(e.target.value);
            }}
          />
    );
}

export default InvoiceNumber;