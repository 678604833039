import { NavigateFunction } from "react-router-dom";
import { GlobalStateAction, LocalStateAction } from "../../../models";
import {
  CompletedInvoiceData,
  DraftInvoiceData,
  FailedInvoiceData,
} from "../models";
import data from "../../../dummy/DummyData.json";

export const handleAddInvoice = async (
  newInvoice: any,
  Navigate: NavigateFunction,
  setOtp: GlobalStateAction<number | null>,
  setCreating: LocalStateAction<boolean>
) => {
  setCreating(true);
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      setOtp(512367);
      resolve();
    }, 2000);
  });
  Navigate("/shareinvoice");
  setCreating(false);
};

export const handleFetchDraftInvoices = (): Promise<DraftInvoiceData[]> => {
  return new Promise<DraftInvoiceData[]>((resolve, reject) => {
    setTimeout(() => {
      resolve(data.DraftInvoices);
    }, 2000);
  });
};

export const handleFetchCompletedInvoices = (): Promise<
  CompletedInvoiceData[]
> => {
  return new Promise<CompletedInvoiceData[]>((resolve, reject) => {
    setTimeout(() => {
      resolve(data.CompletedInvoices);
    }, 2000);
  });
};

export const handleFetchFailedInvoices = (): Promise<FailedInvoiceData[]> => {
  return new Promise<FailedInvoiceData[]>((resolve, reject) => {
    setTimeout(() => {
      resolve(data.FailedInvoices);
    }, 2000);
  });
};

export const handleHomeButton = async (Navigate: NavigateFunction) => {
  try {
    Navigate("/");
  } catch (error) {}
};

export const handleMenuButton = async (Navigate: NavigateFunction) => {
  try {
    Navigate("/menu");
  } catch (error) {}
};
