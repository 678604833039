import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { dropNameAtom } from "../../states/invoicesState";

function DropName() {
    const [dropName, setDropName] = useAtom(dropNameAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Drop Name"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={dropName}
            onChange={(e) => {
              setDropName(e.target.value);
            }}
          />
    );
}

export default DropName;