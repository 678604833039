import { useAtom } from "jotai";
import { goodsWeightAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function GoodsWeight() {
    const [goodsWeight, setGoodsWeight] = useAtom(goodsWeightAtom);

    return (
        <TextField
            label="Goods Weight"
            id="outlined-end-adornment"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            fullWidth
            value={goodsWeight}
            type="number"
            onChange={(e) => {
              setGoodsWeight(e.target.value);
            }}
          />
    );
}

export default GoodsWeight;