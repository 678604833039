import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LogOutButton from "./LogOutButton";
import { FC, PropsWithChildren } from "react";
import BackButton from "./BackButton";
import MenuToggleButton from "./MenuToggle";

interface AppBarProps extends PropsWithChildren {
  backButtonVisible: boolean
};

const Appbar: FC<AppBarProps> = ({backButtonVisible, children}) => {
  return (
    <AppBar position="static">
      <Toolbar sx={{justifyContent: "space-between"}}>
        {backButtonVisible && <BackButton />}
        {!backButtonVisible && <MenuToggleButton />}
        {children}
        <LogOutButton />
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
