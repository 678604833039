import { Grid, Typography } from "@mui/material";
import BuyerDtlsAddr1 from "../BuyerDetails/Addr1";
import BuyerDtlsAddr2 from "../BuyerDetails/Addr2";
import BuyerDtlsGstin from "../BuyerDetails/Gstin";
import BuyerDtlsLglNm from "../BuyerDetails/LglNm";
import BuyerDtlsLoc from "../BuyerDetails/Loc";
import BuyerDtlsPin from "../BuyerDetails/Pin";
import BuyerDtlsPos from "../BuyerDetails/Pos";
import BuyerDtlsStcd from "../BuyerDetails/Stcd";
import BuyerDtlsTrdNm from "../BuyerDetails/TrdNm";

function BuyerDetails() {
  return (
    <Grid container>
      <Typography>Buyer Details</Typography>
      <hr className="full-width" />
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsLglNm />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsTrdNm />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsGstin />
      </Grid>
      <Grid xs={12} sm={6} md={1.5} padding="20px" className="invoice-date">
        <BuyerDtlsStcd />
      </Grid>
      <Grid xs={12} sm={6} md={1.5} padding="20px" className="invoice-date">
        <BuyerDtlsPos />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsAddr1 />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsAddr2 />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsLoc />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <BuyerDtlsPin />
      </Grid>
    </Grid>
  );
}

export default BuyerDetails;
