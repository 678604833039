import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { handleLogInWithEmailPassword } from "../../authentication/helpers";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const LoginWithEmailPassword = () => {
  const [username, setUsername] = useState<string>("");
  const [passcode, setPasscode] = useState<string>("");
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  return (
    <form
      onSubmit={(e) =>
        handleLogInWithEmailPassword(
          e,
          username,
          passcode,
          setUsername,
          setPasscode,
          setLoggingIn
        )
      }
    >
      <Box className="center-everything">
        <Typography
          variant="h2"
          component="h2"
          style={{ marginBottom: "20px", marginTop: 0, paddingTop: 0 }}
        >
          Log In
        </Typography>
        <TextField
          label="Email Address"
          variant="outlined"
          type="email"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ margin: "10px" }}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          required
          value={passcode}
          style={{ margin: "10px" }}
          onChange={(e) => setPasscode(e.target.value)}
        />
        <Button
          variant="contained"
          type="submit"
          style={{ margin: "10px", width: "195px" }}
          disabled={loggingIn}
        >
          {loggingIn ? "Logging In" : "Log In"}
          {loggingIn ? (
            <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
          ) : null}
        </Button>
      </Box>
    </form>
  );
};

export default LoginWithEmailPassword;
