import { useAtom } from "jotai";
import { qualityAllowanceAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function QualityAllowance() {
    const [qualityAllowance, setQualityAllowance] = useAtom(qualityAllowanceAtom);

    return <TextField
    id="outlined-basic"
    label="Quality Allowance"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={qualityAllowance}
    onChange={(e) => {
        setQualityAllowance(e.target.value);
    }}
  />;
};

export default QualityAllowance;