import { useAtom } from "jotai";
import { docDtlsDtAtom } from "../../states/DebitCreditNotesState";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";

function DocDtlsDt() {
    const [docDtlsDt, setDocDtlsDt] = useAtom(docDtlsDtAtom);

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer
      components={["DatePicker"]}
      sx={{ p: 0, width: "100%", overflow: "visible" }}
    >
      <DatePicker
        label="Document Date"
        slotProps={{ textField: { fullWidth: true } }}
        value={docDtlsDt}
        format="DD/MM/YYYY"
        onChange={(e: Dayjs | null) => {
          setDocDtlsDt(e);
        }}
      />
    </DemoContainer>
  </LocalizationProvider>;;
};

export default DocDtlsDt;