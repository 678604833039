import { useAtom } from "jotai";
import { buyerDtlsAddr1Atom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsAddr1() {
    const [buyerDtlsAddr1, setBuyerDtlsAddr1] = useAtom(buyerDtlsAddr1Atom);

    return <TextField
    id="outlined-basic"
    label="Addr1"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsAddr1}
    onChange={(e) => {
        setBuyerDtlsAddr1(e.target.value);
    }}
  />;
};

export default BuyerDtlsAddr1;