import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useAtom } from 'jotai';
import { showDialogBoxAtom, draftInvoiceFormModeAtom, prepareForDraftInvoiceEditAtom, selectedDraftInvoiceAtom } from '../states/invoicesState';
import { DraftInvoiceFormMode } from '../models';
import { useNavigate } from 'react-router-dom';
import { showPDF } from "../helpers/ShowInvoicePDF";
import { useEffect, useState } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DraftDialogBox() {
  const [showDialogBox, setShowDialogBox] = useAtom(showDialogBoxAtom);
  const [,prepareForDraftInvoiceEdit] = useAtom(prepareForDraftInvoiceEditAtom);
  const [, setDraftInvoiceFormMode] = useAtom(draftInvoiceFormModeAtom);
  const [selectedDraftInvoice] = useAtom(selectedDraftInvoiceAtom);
  const [pdfDataURL, setPDFDataURL] = useState<string>("");
  const Navigate = useNavigate();

  const handleClose = () => {
    setShowDialogBox(false);
  };

  useEffect(()=>{
    (async () => {
      if (selectedDraftInvoice) {
        const dataURL = await showPDF(selectedDraftInvoice);
        setPDFDataURL(dataURL);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDraftInvoice])

  return (
      <Dialog
        fullScreen
        open={showDialogBox}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Draft Invoice PDF
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
                setDraftInvoiceFormMode(DraftInvoiceFormMode.EDIT);
                prepareForDraftInvoiceEdit();
                Navigate("/draftinvoiceform");
            }}>
              Edit
            </Button>
          </Toolbar>
        </AppBar>
        {/* <List>
          <ListItem button>
            <ListItemText primary="Company" secondary={selectedDraftInvoice?.companyName} />
          </ListItem>
          <Divider />
        </List> */}
        {pdfDataURL && <embed className="draftpdf" type="application/pdf" src={pdfDataURL} style={{overflow: "auto", width: "100%", height: "100%"}}></embed>}
      </Dialog>
    );
  }