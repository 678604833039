import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pressNumber6Atom } from "../../states/invoicesState";

function PressNumber6() {
    const [pressNumber6, setPressNumber6] = useAtom(pressNumber6Atom);

    return(
        <TextField
            id="outlined-basic"
            label="Press No. 6"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pressNumber6}
            onChange={(e) => {
              setPressNumber6(e.target.value);
            }}
          />
    );
}

export default PressNumber6;