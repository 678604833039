import { Grid } from "@mui/material";
import Allowance from "../GoodsDetails/Allowance";
import DeliveryExpense from "../GoodsDetails/DeliveryExpense";
import FinalAmount from "../GoodsDetails/FinalAmount";
import GoodsWeight from "../GoodsDetails/GoodsWeight";
import Insurance from "../GoodsDetails/Insurance";
import Quantity from "../GoodsDetails/Quantity";
import Rate from "../GoodsDetails/Rate";
import TCS from "../GoodsDetails/TCS";
import Tare from "../GoodsDetails/Tare";

function GoodsDetails() {
  return (
    <Grid container>
      <hr className="full-width" />

      <Grid xs={12} sm={6} md={3} padding="20px" className="goods-weight">
        <GoodsWeight />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="tare">
        <Tare />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="quantity">
        <Quantity />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="delivery-expection">
        <DeliveryExpense />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="rate">
        <Rate />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="tcs">
        <TCS />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="allowance">
        <Allowance />
      </Grid>

      <Grid xs={12} sm={6} md={2} padding="20px" className="insurance">
        <Insurance />
      </Grid>

      <Grid xs={12} sm={6} md={3} padding="20px" className="final-amount">
        <FinalAmount />
      </Grid>
    </Grid>
  );
}

export default GoodsDetails;
