import { User } from "firebase/auth";
import { atom } from "jotai";
import dayjs, { Dayjs } from "dayjs";
import {
  CompletedInvoiceData,
  DraftInvoiceData,
  DraftInvoiceFormMode,
  FailedInvoiceData,
} from "../models";

export const userAtom = atom<User | null | undefined>(undefined);
export const otpAtom = atom<number | null>(null);
export const invoiceDateAtom = atom<Dayjs | null>(dayjs(new Date()));
export const contractNumberAtom = atom<string>("");
export const invoiceNumberAtom = atom<string>("");
export const documentNumberAtom = atom<string>("");
export const companyAtom = atom<string>("");
export const buyerNameAtom = atom<string>("");
export const materialAtom = atom<string>("");
export const stationAtom = atom<string>("");
export const pressNumber1Atom = atom<string>("");
export const pressNumber2Atom = atom<string>("");
export const pressNumber3Atom = atom<string>("");
export const pressNumber4Atom = atom<string>("");
export const pressNumber5Atom = atom<string>("");
export const pressNumber6Atom = atom<string>("");
export const goodsWeightAtom = atom<string>("");
export const tareAtom = atom<string>("");
export const quantityAtom = atom<string>("");
export const deliveryExpenseAtom = atom<string>("");
export const rateAtom = atom<string>("");
export const TCSAtom = atom<string>("");
export const allowanceAtom = atom<string>("");
export const insuranceAtom = atom<string>("");
export const finalAmountAtom = atom<string>("");
export const transporterAtom = atom<string>("");
export const truckNumberAtom = atom<string>("");
export const builtyNumberAtom = atom<string>("");
export const builtyDateAtom = atom<Dayjs | null>(dayjs(new Date()));
export const pickUpNameAtom = atom<string>("");
export const pickUpAddressAtom = atom<string>("");
export const pickUpPlaceAtom = atom<string>("");
export const pickUpPinCodeAtom = atom<string>("");
export const pickUpStateAtom = atom<string>("");
export const dropNameAtom = atom<string>("");
export const dropAddressAtom = atom<string>("");
export const dropPlaceAtom = atom<string>("");
export const dropPinCodeAtom = atom<string>("");
export const dropStateAtom = atom<string>("");
export const remarksAtom = atom<string>("");
export const emailToAtom = atom<string>("");
export const buyerGSTINAtom = atom<string>("");
export const buyerAddressAtom = atom<string>("");
export const buyerPlaceAtom = atom<string>("");
export const buyerStateAtom = atom<string>("");
export const buyerPinCodeAtom = atom<string>("");
export const gstStateAtom = atom<string>("");
export const hsnCodeAtom = atom<string>("");
export const qualityAtom = atom<string>("");
export const dueDateAtom = atom<Dayjs | null>(dayjs(new Date()));

export const selectedDraftInvoiceAtom = atom<DraftInvoiceData | null>(null);
export const selectedCompletedInvoiceAtom = atom<CompletedInvoiceData | null>(
  null
);
export const selectedFailedInvoiceAtom = atom<FailedInvoiceData | null>(null);
export const showDialogBoxAtom = atom<boolean>(false);

export const draftInvoicesAtom = atom<DraftInvoiceData[]>([]);
export const draftInvoiceFormModeAtom = atom<DraftInvoiceFormMode>(
  DraftInvoiceFormMode.CREATE
);
export const prepareForDraftInvoiceEditAtom = atom(null, (get, set) => {
  const selectedDraftInvoice = get(selectedDraftInvoiceAtom);
  if (selectedDraftInvoice) {
    set(invoiceDateAtom, dayjs(selectedDraftInvoice.invoiceDate));
    set(contractNumberAtom, selectedDraftInvoice.contractNumber);
    set(invoiceNumberAtom, selectedDraftInvoice.invoiceNumber);
    set(documentNumberAtom, selectedDraftInvoice.documentNumber);
    set(companyAtom, selectedDraftInvoice.companyName);
    set(buyerNameAtom, selectedDraftInvoice.buyerName);
    set(materialAtom, selectedDraftInvoice.material);
    set(stationAtom, selectedDraftInvoice.station);
    set(pressNumber1Atom, selectedDraftInvoice.pressNumber1);
    set(pressNumber2Atom, selectedDraftInvoice.pressNumber2);
    set(pressNumber3Atom, selectedDraftInvoice.pressNumber3);
    set(pressNumber4Atom, selectedDraftInvoice.pressNumber4);
    set(pressNumber5Atom, selectedDraftInvoice.pressNumber5);
    set(pressNumber6Atom, selectedDraftInvoice.pressNumber6);
    set(goodsWeightAtom, selectedDraftInvoice.goodsWeight);
    set(tareAtom, selectedDraftInvoice.tare);
    set(quantityAtom, selectedDraftInvoice.quantity);
    set(deliveryExpenseAtom, selectedDraftInvoice.deliveryExpense);
    set(rateAtom, selectedDraftInvoice.rate);
    set(TCSAtom, selectedDraftInvoice.tcs);
    set(allowanceAtom, selectedDraftInvoice.allowance);
    set(insuranceAtom, selectedDraftInvoice.insurance);
    set(finalAmountAtom, selectedDraftInvoice.finalAmount);
    set(transporterAtom, selectedDraftInvoice.transporter);
    set(truckNumberAtom, selectedDraftInvoice.truckNumber);
    set(builtyNumberAtom, selectedDraftInvoice.builtyNumber);
    set(builtyDateAtom, dayjs(selectedDraftInvoice.builtyDate));
    set(pickUpNameAtom, selectedDraftInvoice.pickUpName);
    set(pickUpAddressAtom, selectedDraftInvoice.pickUpAddress);
    set(pickUpPlaceAtom, selectedDraftInvoice.pickUpPlace);
    set(pickUpPinCodeAtom, selectedDraftInvoice.pickUpPinCode);
    set(pickUpStateAtom, selectedDraftInvoice.pickUpState);
    set(dropNameAtom, selectedDraftInvoice.dropName);
    set(dropAddressAtom, selectedDraftInvoice.dropAddress);
    set(dropPlaceAtom, selectedDraftInvoice.dropPlace);
    set(dropPinCodeAtom, selectedDraftInvoice.dropPinCode);
    set(dropStateAtom, selectedDraftInvoice.dropState);
    set(remarksAtom, selectedDraftInvoice.remarks);
    set(emailToAtom, selectedDraftInvoice.emailTo);
  }
});

export const resetDraftInvoiceFormAtom = atom(null, (get, set) => {
  const draftInvoiceFormMode = get(draftInvoiceFormModeAtom);
  if (draftInvoiceFormMode === DraftInvoiceFormMode.EDIT) {
    set(prepareForDraftInvoiceEditAtom);
  } else {
    set(invoiceDateAtom, dayjs(new Date()));
    set(contractNumberAtom, "");
    set(invoiceNumberAtom, "");
    set(documentNumberAtom, "");
    set(companyAtom, "");
    set(buyerNameAtom, "");
    set(materialAtom, "");
    set(stationAtom, "");
    set(pressNumber1Atom, "");
    set(pressNumber2Atom, "");
    set(pressNumber3Atom, "");
    set(pressNumber4Atom, "");
    set(pressNumber5Atom, "");
    set(pressNumber6Atom, "");
    set(goodsWeightAtom, "");
    set(tareAtom, "");
    set(quantityAtom, "");
    set(deliveryExpenseAtom, "");
    set(rateAtom, "");
    set(TCSAtom, "");
    set(allowanceAtom, "");
    set(insuranceAtom, "");
    set(finalAmountAtom, "");
    set(transporterAtom, "");
    set(truckNumberAtom, "");
    set(builtyNumberAtom, "");
    set(builtyDateAtom, dayjs(new Date()));
    set(pickUpNameAtom, "");
    set(pickUpAddressAtom, "");
    set(pickUpPlaceAtom, "");
    set(pickUpPinCodeAtom, "");
    set(pickUpStateAtom, "");
    set(dropNameAtom, "");
    set(dropAddressAtom, "");
    set(dropPlaceAtom, "");
    set(dropPinCodeAtom, "");
    set(dropStateAtom, "");
    set(remarksAtom, "");
    set(emailToAtom, "");
  }
});