import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { handleFetchDraftInvoices } from "../helpers";
import { DraftInvoiceData } from "../models";
import Grid from "@mui/material/Unstable_Grid2";
import DraftDialogBox from "./DraftDialogBox";
import { draftInvoicesAtom, selectedDraftInvoiceAtom, showDialogBoxAtom } from "../states/invoicesState";
import { useAtom } from "jotai";

function DraftUbvoiceList() {
  const [draftInvoices, setDraftInvoices] = useAtom(draftInvoicesAtom);
  const [, setShowDialogBox] = useAtom(showDialogBoxAtom);
  const [, setSelectedDraftInvoice] = useAtom(selectedDraftInvoiceAtom);

  useEffect(() => {
    (async () => {
      const DraftInvoicesData = await handleFetchDraftInvoices();
      setDraftInvoices(DraftInvoicesData);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchMoreDraftInvoiceData() {
    // setFetching(true);
    setTimeout(() => {
      console.log("fetching more draft");
      // setDraftInvoices([...draftInvoices]);
      // setFetching(false);
    }, 1500);
  }

  function handleSingleClick(draftInvoice: DraftInvoiceData) {
    setSelectedDraftInvoice(draftInvoice);
    setShowDialogBox(true);
  }

  return (
    <List
      id="draft-invoice-list"
      style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
    >
      <InfiniteScroll
        dataLength={draftInvoices.length}
        next={() => {
          // if (!fetching) {
          fetchMoreDraftInvoiceData();
          // }
        }}
        hasMore={true}
        scrollableTarget="draft-invoice-list"
        loader={
          <div>
            <LinearProgress />
          </div>
        }
        endMessage={<p>No more data to load.</p>}
      >
        {draftInvoices.map((draftInvoice: DraftInvoiceData, index) => [
          <ListItemButton
            key={"list-item" + index}
            onClick={() => {
              handleSingleClick(draftInvoice);
            }}
          >
            <Grid container width="100%">
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="draft-invoice-invoice-date"
              >
                {draftInvoice.companyName}
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="draft-invoice-invoice-number"
              >
                {draftInvoice.buyerName}
              </Grid>
              <Grid xs={12} sm={6} md={3} className="draft-invoice-buyer-name">
                {draftInvoice.contractNumber}
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="draft-invoice-company-name"
              >
                {draftInvoice.quantity}
              </Grid>
            </Grid>
          </ListItemButton>,
          <Divider
            key={"list-item-divider-" + index}
            variant="middle"
            component="li"
          />,
        ])}
      </InfiniteScroll>
      <DraftDialogBox />
    </List>
  );
}

export default DraftUbvoiceList;
