import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { dropPinCodeAtom } from "../../states/invoicesState";

function DropPinCode() {
    const [dropPinCode, setDropPinCode] = useAtom(dropPinCodeAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Pin Code"
            variant="outlined"
            autoComplete="off"
            fullWidth
            type="number"
            value={dropPinCode}
            onChange={(e) => {
              setDropPinCode(e.target.value);
            }}
          />
    );
}

export default DropPinCode;