import { useAtom } from "jotai";
import { docDtlsTypAtom } from "../../states/DebitCreditNotesState";
import Select from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { DebitCreditNoteType } from "../../models";

function DocDtlsTyp() {
    const [docDtlsTyp, setDocDtlsTyp] = useAtom(docDtlsTypAtom);

    return (
    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Type</InputLabel>
    <Select
    id="demo-simple-select"
    label="Type"
    labelId="demo-simple-select-label"
    variant="outlined"
    value={docDtlsTyp}
    fullWidth
    onChange={(e) => {
        setDocDtlsTyp(e.target.value as DebitCreditNoteType);
    }}
  >
    <MenuItem value={DebitCreditNoteType.CreditNote}>Credit Note</MenuItem>
    <MenuItem value={DebitCreditNoteType.DebitNote}>Debit Note</MenuItem>
  </Select></FormControl>);
};

export default DocDtlsTyp;