import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pressNumber4Atom } from "../../states/invoicesState";

function PressNumber4() {
    const [pressNumber4, setPressNumber4] = useAtom(pressNumber4Atom);

    return (
        <TextField
            id="outlined-basic"
            label="Press No. 4"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pressNumber4}
            onChange={(e) => {
              setPressNumber4(e.target.value);
            }}
          />
    );
}

export default PressNumber4;