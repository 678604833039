import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pressNumber3Atom } from "../../states/invoicesState";

function PressNumber3() {
    const [pressNumber3, setPressNumber3] = useAtom(pressNumber3Atom);

    return (
        <TextField
            id="outlined-basic"
            label="Press No. 3"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pressNumber3}
            onChange={(e) => {
              setPressNumber3(e.target.value);
            }}
          />
    );
}

export default PressNumber3;