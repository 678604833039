import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  otpAtom,
  userAtom,
  resetDraftInvoiceFormAtom,
} from "../states/invoicesState";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, CircularProgress, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { handleAddInvoice } from "../helpers";
import { GlobalStateAction } from "../../../models";
import useCreateInvoice from "../hooks/use-CreateInvoice";
import useCopyInvoice from "../hooks/use-CopyInvoice";
import Appbar from "../../../components/AppBar/AppBar";
import InvoiceDetails from "../components/InvoiceForm/InvoiceDetails";
import GoodsDetails from "../components/InvoiceForm/GoodsDetails";
import TransporterDetails from "../components/InvoiceForm/TransporterDetails";
import PickUpDetails from "../components/InvoiceForm/PickUpDetails";
import DropDetails from "../components/InvoiceForm/DropDetails";
import RemarksandEmail from "../components/InvoiceForm/RemarksandEmail";

const CreateInvoiceForm = () => {
  const Navigate = useNavigate();
  const [user] = useAtom(userAtom);
  const [, setOtp] = useAtom(otpAtom);
  const [creating, SetCreating] = useState<boolean>(false);
  const { newInvoice } = useCreateInvoice();
  const { useCopiedContent } = useCopyInvoice();
  const [, resetDraftInvoiceForm] = useAtom(resetDraftInvoiceFormAtom);

  useEffect(() => {
    if (!user) {
      return Navigate("/login");
    }
  }, [user, Navigate]);

  function handleInvoiceFormSubmit(setOtp: GlobalStateAction<number | null>) {
    handleAddInvoice(newInvoice, Navigate, setOtp, SetCreating);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Appbar backButtonVisible={true}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Create Draft Invoice
        </Typography>
      </Appbar>

      <Grid container marginTop="10px" padding="20px">
        <InvoiceDetails />
        <GoodsDetails />
        <TransporterDetails />
        <PickUpDetails />
        <DropDetails />
        <RemarksandEmail />
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        paddingBottom="20px"
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{ px: 5 }}
          onClick={() => {
            resetDraftInvoiceForm();
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{ px: 5 }}
          disabled={creating}
          onClick={() => {
            handleInvoiceFormSubmit(setOtp);
          }}
        >
          {creating ? "Creating" : "Create"}
          {creating ? (
            <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
          ) : null}
        </Button>
        <Button onClick={useCopiedContent}>Copy</Button>
      </Stack>
    </Box>
  );
};

export default CreateInvoiceForm;
