import LogInPage from "./features/authentication/pages/LogInPage";
import FirebaseAuthService from "./firebase/auth";
import InvoiceHomePage from "./features/invoices/pages/InvoiceHomePage";
import DraftInvoiceForm from "./features/invoices/pages/DraftInvoiceForm";
import DebitCreditNoteFrom from "./features/debitNoteCreditNote/pages/Debit-CreditNoteForm";
import { useAtom } from "jotai";
import { userAtom } from "./features/invoices/states/invoicesState";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ShareIncoicePage from "./features/invoices/pages/ShareInvoicePage";
import DebitCreditNoteHomePage from "./features/debitNoteCreditNote/pages/Debit-CreditNoteHomePage";

function Router() {
  const [, setUser] = useAtom(userAtom);
  FirebaseAuthService.subscribeToAuthChanges(setUser);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <InvoiceHomePage />,
    },
    {
      path: "/login",
      element: <LogInPage />,
    },
    {
      path: "/draftinvoiceform",
      element: <DraftInvoiceForm />,
    },
    {
      path: "/shareinvoice",
      element: <ShareIncoicePage />,
    },
    {
      path: "create-debit-credit-note",
      element: <DebitCreditNoteFrom />,
    },
    {
      path: "/debit-credit-note-homepage",
      element: <DebitCreditNoteHomePage />,
    }
  ]);

  return <RouterProvider router={router} />;
}

export default Router;
