import jsPDF from "jspdf";
import { robotoRegularData } from "../../../helpers/fontsData/robotoRegular";
import { robotoBoldData } from "../../../helpers/fontsData/robotoBold";
import { robotoItalicData } from "../../../helpers/fontsData/robotoItalic";
import { robotoBoldItalicData } from "../../../helpers/fontsData/robotoBoldItalic";
import { DraftInvoiceData } from "../models";
import Digit2Word from "../../../helpers/InWords";

export const showPDF = (draftInvoice: DraftInvoiceData): string => {
  const doc = new jsPDF({
    format: "a4",
  });

  doc.setFillColor(238, 238, 238);
  doc.setDrawColor(86, 86, 86);
  doc.rect(18.5, 20, 174, 80, "FD");

  doc.setLineWidth(0.1);
  doc.line(105, 100, 105, 140);

  doc.rect(18.5, 140, 174, 138);
  doc.line(18.5, 148, 192.5, 148);
  doc.line(18.5, 156, 83, 156);
  doc.line(83, 140, 83, 188);
  doc.line(55, 140, 55, 156);
  doc.line(18.5, 188, 118, 188);
  doc.line(118, 140, 118, 238);
  doc.line(83, 167, 162, 167);
  doc.line(18.5, 238, 192.5, 238);
  doc.line(18.5, 248, 192.5, 248);
  doc.line(140.5, 248, 140.5, 278);
  doc.line(140.5, 256, 192.5, 256);
  doc.line(162, 140, 162, 248);
  doc.line(140, 140, 140, 167);
  doc.line(18.5, 213, 118, 213);  
  doc.line(83, 90, 83, 100);
  doc.line(136, 90, 136, 100); 
  doc.line(162, 188, 192.5, 188);
  doc.line(162, 194, 192.5, 194);

  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegularData);
  doc.addFont("Roboto-Regular.ttf", "roboto", "regular");
  doc.addFileToVFS("Roboto-Bold.ttf", robotoBoldData);
  doc.addFont("Roboto-Bold.ttf", "roboto", "bold");
  doc.addFileToVFS("Roboto-Italic.ttf", robotoItalicData);
  doc.addFont("Roboto-Italic.ttf", "roboto", "italic");
  doc.addFileToVFS("Roboto-Bold-Italic.ttf", robotoBoldItalicData);
  doc.addFont("Roboto-Bold-Italic.ttf", "roboto", "bold-italic")
  doc.addFileToVFS("Roboto-Medium.ttf", robotoBoldData);
  doc.addFont("Roboto-Medium.ttf", "roboto", "medium");

  doc.setFontSize(13);
  doc.setFont("roboto", "italic");
  doc.text("TAX INVOICE", 58.5, 29);

  doc.setFontSize(8.5);
  doc.setFont("roboto", "regular");
  doc.text("SUBJECTED TO AHMEDABAD JURISDICTION", 41.5, 36);
  doc.line(41.5, 36.5, 102, 36.5);

  doc.setFontSize(19);
  doc.setFont("roboto", "bold");
  //@ts-ignore
  doc.text(`${draftInvoice.companyName}`, 72.5, 46, null, null, "center");

  doc.setFontSize(9)
  doc.setFont("roboto", "medium");
  doc.text("COTTON MERCHANT", 57, 54);

  doc.setFontSize(8);
  doc.setFont("roboto", "regular");
  doc.text("1, Nikunj Apartment 4 Kalpana Society, Bh. Navarangpura Post-Office,", 30, 60);
  doc.text("Ahmedabad-380009", 60, 64);

  doc.setFontSize(8);
  doc.setFont("roboto", "regular");
  doc.text("EMAIL:", 28.5, 69.2);
  doc.text("PHONE(o):", 75, 69.2);
  doc.setFont("roboto", "bold");
  doc.text("ajdalal0021@yahoo.com", 40, 69);
  doc.text("079-48004717/40021535", 90, 69.2);

  doc.setFontSize(9);
  doc.setFont("roboto", "regular");
  doc.text("GSTIN NO:", 53, 76.5);
  doc.text("PAN NO:", 56, 82);
  doc.setFont("roboto", "bold");
  doc.text("24AAPFH0445M1Z6", 70, 76.5);
  doc.text("AAPFH0445M", 75, 82);

  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  doc.text("IRN:", 20, 95.5);
  const irnTextLine = doc.setFont("roboto", "regular").setFontSize(8).splitTextToSize("e7a44ef57afde1a9adf16a9024c46d34d0eb931927411b819f22626176b16b79", 54.5);
  doc.text(irnTextLine, 28, 94);


  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  doc.text("Ack No:", 88, 95.5);
  doc.setFont("roboto", "regular");
  doc.setFontSize(9);
  doc.text("162313796286422", 102, 95.5);

  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  doc.text("Ack Date:", 140, 95.5);
  doc.setFont("roboto", "regular");
  doc.setFontSize(9);
  doc.text("15-4-2023 14:28:00", 158, 95.5);

  doc.setFontSize(10);
  doc.setFont("roboto", "regular");
  doc.text("Date: ", 20, 106);
  doc.setFont("roboto", "bold");
  const invoice_date = new Date(Number(draftInvoice.invoiceDate)).getDate();
  const invoice_month_1 = new Date(Number(draftInvoice.invoiceDate)).getMonth();
  const invoice_year = new Date(Number(draftInvoice.invoiceDate)).getFullYear();
  if (invoice_month_1 < 9) {
    const invoice_month = `0${invoice_month_1}`;
    doc.text(`${invoice_date}-${invoice_month}-${invoice_year}`, 30, 106);
  } else {
    const invoice_month = invoice_month_1;
    doc.text(`${invoice_date}-${invoice_month}-${invoice_year}`, 30, 106);
  }

  doc.setFontSize(10);
  doc.setFont("roboto", "regular");
  doc.text("Bill To:", 20, 113);
  doc.setFontSize(9.5);
  const billToAddressTextLine = doc.splitTextToSize(`${draftInvoice.buyerAddress}, ${draftInvoice.buyerPlace}, ${draftInvoice.buyerState} - ${draftInvoice.buyerPinCode}`, 88);
  doc.text(billToAddressTextLine, 20, 125);
  doc.setFontSize(10);
  doc.text("GSTIN:", 20, 136);
  doc.setFont("roboto", "bold");
  doc.text(`${draftInvoice.buyerName}`, 20, 119);
  doc.text(`${draftInvoice.buyerGSTIN}`, 35, 136);

  doc.setFontSize(10);
  doc.setFont("roboto", "regular");
  doc.text("Invoice No:", 161, 106);
  doc.setFont("roboto", "bold");
  doc.text(`${draftInvoice.invoiceNumber}`, 183.5, 106);

  doc.setFont("roboto", "regular");
  doc.text("Deliveru To:", 173, 113);
  const deliveryToAddressTextLine = doc.splitTextToSize("Lorem Ipsum Address", 88);
  doc.setFontSize(9.5);
  // @ts-ignore
  doc.text(deliveryToAddressTextLine, 192.5, 125, null, null, "right");
  doc.setFont("roboto", "bold");
  doc.setFontSize(10);
  // @ts-ignore
  doc.text("Lorem Ipsum", 192.5, 119, null, null, "right");

  doc.setFont("roboto", "regular");
  doc.text("Details of Goods", 23.5, 145);
  doc.text("HSNCODE", 61, 145);
  doc.text("Quantity in Kg.", 89, 145);
  doc.text("G.W.", 85, 153);
  doc.text(":", 103.5, 153);
  doc.text(`${draftInvoice.goodsWeight}`, 106, 153);
  doc.text("Tare", 85, 159);
  doc.text(":", 103.5, 159);
  doc.text(`${draftInvoice.tare}`, 109, 159);
  doc.text("Allowance", 85, 165);
  doc.text(":", 103.5, 165);
  //@ts-ignore
  doc.text(`${draftInvoice.allowance}`, 111, 165, null, null, "center");
  doc.text("Candy Rate", 120, 145);
  //@ts-ignore
  doc.text(`${draftInvoice.rate}`, 129, 157.5, null, null, "center");
  doc.text("Quintal Rate", 141.5, 145);
  doc.setFont("roboto", "bold");
  if(draftInvoice.material === "COTTON"){
    doc.text("F. P. Cotton Bales", 23, 153);
  }
  //@ts-ignore
  doc.text(`${draftInvoice.hsnCode}`, 68.5, 153, null, null, "center");
  const quintalRate = Number(draftInvoice.rate) * 0.2812;
  //@ts-ignore
  doc.text(`${quintalRate}`, 151, 157.5, null, null, "center");
  doc.text("NET", 85, 171.5);
  doc.text(":", 103.5, 171.5);
  const netWeight = Number(draftInvoice.goodsWeight) - Number(draftInvoice.tare) - Number(draftInvoice.allowance);
  //@ts-ignore
  doc.text(`${netWeight}`, 111, 171.5, null, null, "center");

  doc.setFont("roboto", "regular");
  doc.text("Amount", 171, 145);
  doc.setFont("roboto", "bold");
  const amount = (quintalRate) * (netWeight/100);
  //@ts-ignore
  doc.text(`${amount}`, 177, 157.5, null, null, "center");

  doc.setFont("roboto", "regular");
  doc.text("Press No:", 20, 162);
  doc.text("Station:", 20, 168);
  doc.text("Quality:", 20, 174);
  doc.text("Bales:", 20, 180);
  doc.text("Lot No:", 20, 186);
  doc.setFont("roboto", "bold");
  doc.setFontSize(9);
  doc.text(`${draftInvoice.pressNumber1}, `, 40, 162);
  doc.text(`${draftInvoice.pressNumber2}`, 62, 162);
  doc.setFontSize(10);
  doc.text(`${draftInvoice.station}`, 40, 168);
  doc.text(`${draftInvoice.quality}`, 40, 174);
  doc.text(`${draftInvoice.quantity}`, 40, 180);

  doc.setFont("roboto", "regular");
  doc.text("Against GST:", 20, 192);
  doc.text("Remark:", 20, 210);
  doc.setFont("roboto", "bold-italic");
  doc.text("Insurance Cover by Buyer", 20, 204);
  doc.setFont("roboto", "bold");
  doc.text(`${draftInvoice.gstState}`, 48, 192);
  doc.text(`${draftInvoice.remarks}`, 36, 210);

  doc.setFontSize(10);
  doc.setFont("roboto", "regular");
  doc.text("Due Date:", 20, 198);
  doc.setFont("roboto", "bold");
  const due_date = new Date(Number(draftInvoice.dueDate)).getDate();
  const due_month_1 = new Date(Number(draftInvoice.dueDate)).getMonth();
  const due_year = new Date(Number(draftInvoice.dueDate)).getFullYear();
  if (due_month_1 < 9) {
  const due_month = `0${due_month_1}`;
  doc.text(`${due_date}-${due_month}-${due_year}`, 48, 198);
  } else {
  const due_month = due_month_1;
  doc.text(`${due_date}-${due_month}-${due_year}`, 48, 198);
  }

  doc.setFont("roboto", "regular");
  doc.text("Dispatched Thr: ", 20, 217);
  doc.text("Truck No:", 20, 223);
  doc.text("Builty No:", 20, 229);
  doc.setFont("roboto", "bold");
  doc.text(`${draftInvoice.transporter}`, 48, 217);
  doc.text(`${draftInvoice.truckNumber}`, 43, 223);
  doc.text(`${draftInvoice.builtyNumber}`, 43, 229);

    doc.setFontSize(10);
    doc.setFont("roboto", "regular");
    doc.text("Builty Date:", 20, 235);
    doc.setFont("roboto", "bold");
    const builty_date = new Date(Number(draftInvoice.builtyDate)).getDate();
    const builty_month_1 = new Date(Number(draftInvoice.builtyDate)).getMonth();
    const builty_year = new Date(Number(draftInvoice.builtyDate)).getFullYear();
    if (builty_month_1 < 9) {
    const builty_month = `0${builty_month_1}`;
    doc.text(`${builty_date}-${builty_month}-${builty_year}`, 43, 235);
    } else {
    const builty_month = builty_month_1;
    doc.text(`${builty_date}-${builty_month}-${builty_year}`, 43, 235);
    }

  doc.setFont("roboto", "regular");
  doc.text("Our Bank Account Details:", 20, 255);
  doc.setFontSize(9);
  doc.text("Account. No:", 50, 264);
  doc.text("Account. No:", 50, 270);
  doc.text("RTGS No:", 102, 264);
  doc.text("RTGS No:", 102, 270);
  doc.setFont("roboto", "bold");
  doc.text("S.B.I", 41, 264);
  doc.text("41089256104", 72, 264);
  doc.text("Standard Chartered", 20, 270);
  doc.text("23305276551", 72, 270);
  doc.text("SBIN0003792", 118, 264);
  doc.text("SCBL0036051", 118, 270);

  doc.setFont("roboto", "bold");
  doc.text("For", 145, 253)
  doc.setFontSize(10);
  doc.text("HARDAM COTTON LLP", 153, 253);
  
  doc.setFont("roboto", "regular");
  doc.text("Insurance:", 145, 180);
  doc.text("Delivery Exp:", 141.5, 186);

  doc.setFont("roboto", "bold");
  //@ts-ignore
  doc.text(`${draftInvoice.insurance}`, 177, 180, null, null, "center");
  // @ts-ignore
  doc.text(`${draftInvoice.deliveryExpense}`, 177, 186, null, null, "center");
  const amountTillNow = Number(amount) + Number(draftInvoice.insurance) + Number(draftInvoice.deliveryExpense);
  //@ts-ignore
  doc.text(`${amountTillNow}`, 177, 192.2, null, null, "center");
  const taxCalculation1 = (amountTillNow * 0.025);
  const taxAmount1 = parseFloat(taxCalculation1.toFixed(2));

  const taxCalculation2 = (amountTillNow * 0.05);
  const taxAmount2 = parseFloat(taxCalculation2.toFixed(2));

  if(draftInvoice.gstState === "2.5% CGST + 2.5% SGST") {
    doc.setFont("roboto", "regular");
    doc.text("CGST 2.5%:", 143, 217);
    doc.text("SGST 2.5%:", 143, 223);

    doc.setFont("roboto", "bold");
    //@ts-ignore
    doc.text(`${taxAmount1}`, 177, 217, null, null, "center");
    //@ts-ignore
    doc.text(`${taxAmount1}`, 177, 223, null, null, "center");
  } 
  if(draftInvoice.gstState === "5.0% IGST") {
    doc.setFont("roboto", "regular");
    doc.text("IGST 5%:", 143, 217);

    doc.setFont("roboto", "bold");
    //@ts-ignore
    doc.text(`${taxAmount2}`, 177, 217, null, null, "center");
  }

  const finalAmount = Math.ceil(amountTillNow + taxAmount1 * 2);
  doc.setFont("roboto", "bold");
  //@ts-ignore
  doc.text(`Rs.${finalAmount}/-`, 177, 244, null, null, "center");

  doc.setFont("roboto", "regular");
  doc.text("In Words:", 20, 244);
  doc.setFont("roboto", "bold-italic");
  //@ts-ignore
  doc.text(`${Digit2Word(finalAmount)} Rupees Only`, 96, 244, null, null, "center");

  return (doc.output("dataurlstring"));
};