import { useAtom } from "jotai";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { builtyDateAtom } from "../../states/invoicesState";

function BuiltyDate() {
    const [builtyDate, setBuiltyDate] = useAtom(builtyDateAtom);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DatePicker"]}
              sx={{ p: 0, width: "100%", overflow: "visible" }}
            >
              <DatePicker
                label="Builty Date"
                slotProps={{ textField: { fullWidth: true } }}
                value={builtyDate}
                format="DD/MM/YYYY"
                onChange={(e: Dayjs | null) => {
                  setBuiltyDate(e);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
    );
}

export default BuiltyDate;