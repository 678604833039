import { useAtom } from "jotai";
import { itemListQtyAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function ItemListQty() {
    const [itemListQty, setItemListQty] = useAtom(itemListQtyAtom);

    return <TextField
    id="outlined-basic"
    label="Qty"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={itemListQty}
    onChange={(e) => {
      if(e.target.value){
        setItemListQty(parseFloat(e.target.value));
      } else {
        setItemListQty(0);
      }
    }}
  />;
};

export default ItemListQty;