import { useAtom } from "jotai";
import { buyerDtlsLocAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsLoc() {
    const [buyerDtlsLoc, setBuyerDtlsLoc] = useAtom(buyerDtlsLocAtom);

    return <TextField
    id="outlined-basic"
    label="Loc"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsLoc}
    onChange={(e) => {
        setBuyerDtlsLoc(e.target.value);
    }}
  />;
};

export default BuyerDtlsLoc;