import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { userAtom } from "../../invoices/states/invoicesState";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import { Typography, Button, CircularProgress } from "@mui/material";
import usePastedInvoiceData from "../hooks/usePastedInvoiceData";
import { handleCreateDebitCreditNote } from "../helpers";
import useCreateDebitCreditNote from "../hooks/useCreateDebitCreditNote";
import {
  selectedDebitCreditNoteAtom,
  showDialogBoxAtom,
  resetDebitCreditNoteFormAtom,
} from "../states/DebitCreditNotesState";
import DebitCreditNoteDialogBox from "../components/DebitCreditNoteDialogBox";
import Appbar from "../../../components/AppBar/AppBar";
import DebitCreditNoteDetails from "../components/Debit-CreditNoteForm/DebitCreditNotesDetails";
import DocumentDetails from "../components/Debit-CreditNoteForm/DocumentDetails";
import ItemList from "../components/Debit-CreditNoteForm/ItemList";
import BuyerDetails from "../components/Debit-CreditNoteForm/BuyerDetails";
import SellerDetails from "../components/Debit-CreditNoteForm/SellerDetails";

const DebitCreditNoteFrom = () => {
  const [user] = useAtom(userAtom);
  const { useCopiedContent } = usePastedInvoiceData();
  const Navigate = useNavigate();
  const [creating, SetCreating] = useState<boolean>(false);
  const [newDebitCreditNoteRestData] = useCreateDebitCreditNote();
  const [, setShowDialogBox] = useAtom(showDialogBoxAtom);
  const [, setSelectedDebitCreditNote] = useAtom(selectedDebitCreditNoteAtom);
  const [, resetDebitCreditNoteForm] = useAtom(resetDebitCreditNoteFormAtom);

  useEffect(() => {
    if (!user) {
      return Navigate("/login");
    }
  }, [user, Navigate]);

  function handleClickOnPreview() {
    setSelectedDebitCreditNote({
      restData: newDebitCreditNoteRestData,
    });
    setShowDialogBox(true);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Appbar backButtonVisible={true}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Create Debit / Credit Notes
        </Typography>
      </Appbar>

      <Grid container marginTop="10px" padding="20px">
        <DebitCreditNoteDetails />
        <DocumentDetails />
        <ItemList />
        <BuyerDetails />
        <SellerDetails />
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        paddingBottom="20px"
      >
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            resetDebitCreditNoteForm();
          }}
        >
          Reset
        </Button>
        <Button onClick={useCopiedContent} variant="outlined" color="inherit">
          Paste
        </Button>
        <Button
          color="inherit"
          onClick={handleClickOnPreview}
          variant="outlined"
        >
          Preview
        </Button>
        <DebitCreditNoteDialogBox />
        <Button
          variant="contained"
          color="success"
          sx={{ px: 5 }}
          disabled={creating}
          onClick={async () => {
            await handleCreateDebitCreditNote(
              newDebitCreditNoteRestData,
              SetCreating
            );
            Navigate("/debit-credit-note-homepage");
          }}
        >
          {creating ? "Creating" : "Create"}
          {creating ? (
            <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
          ) : null}
        </Button>
      </Stack>
    </Box>
  );
};

export default DebitCreditNoteFrom;
