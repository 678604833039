import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const Navigate = useNavigate();

  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={() => {
        Navigate(-1);
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
}

export default BackButton;
