import { useAtom } from "jotai";
import { trashExpAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function TrashExp() {
    const [trashExp, setTrashExp] = useAtom(trashExpAtom);

    return <TextField
    id="outlined-basic"
    label="Trash Exp"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={trashExp}
    onChange={(e) => {
        setTrashExp(e.target.value);
    }}
  />;
};

export default TrashExp;