import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pressNumber2Atom } from "../../states/invoicesState";

function PressNumber2() {
    const [pressNumber2, setPressNumber2] = useAtom(pressNumber2Atom);

    return (
        <TextField
            id="outlined-basic"
            label="Press No. 2"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pressNumber2}
            onChange={(e) => {
              setPressNumber2(e.target.value);
            }}
          />
    );
}

export default PressNumber2;