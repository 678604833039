import jsPDF from "jspdf";
import { robotoRegularData } from "../../../helpers/fontsData/robotoRegular";
import { robotoBoldData } from "../../../helpers/fontsData/robotoBold";
import { robotoItalicData } from "../../../helpers/fontsData/robotoItalic";
import { robotoBoldItalicData } from "../../../helpers/fontsData/robotoBoldItalic";
import { getOurCompanyData } from "../../../helpers/getCompanyData";
import stateCodeData from "../../../helpers/stateCodetoStateNameData";
import Digit2Word from "../../../helpers/InWords";
import { ConvertNumberToTwoDigitString } from "../../../helpers/utils";
import { DebitCreditNote } from "../models";

export const displayDebitCreditNotePDF = async (newDebitCreditNote: DebitCreditNote) => {
  const doc = new jsPDF({
    format: "a4",
  });

  createTableLineSkeleton(doc);
  importRobotoFontFile(doc);
  fillInHeaderTexts(doc, newDebitCreditNote);
  fillInEInvoiceDetails(doc, newDebitCreditNote);
  fillInDebitCreditNoteNo(doc, newDebitCreditNote);
  fillInDebitCreditNoteDate(doc, newDebitCreditNote);
  fillInOriginalBillNo(doc, newDebitCreditNote);
  fillInOriginalBillDate(doc, newDebitCreditNote);
  fillInReasonFor(doc);
  fillBuyerOrSellerDetails(doc, newDebitCreditNote);
  fillInItemTable(doc, newDebitCreditNote);
  fillInTotalAmount(doc, newDebitCreditNote);
  fillInTermsAndConditions(doc);
  fillInNote(doc, newDebitCreditNote);
  fillInSignature(newDebitCreditNote, doc);

  return doc.output("dataurlstring");
};

function fillInSignature(newDebitCreditNote: DebitCreditNote, doc: jsPDF) {
  const ourCompanyData = getOurCompanyData(
    newDebitCreditNote.restData.companyName
  );
  doc.text(`For, ${ourCompanyData.fromTrdName}`, 190, 257, { align: "right" });
  doc.setFontSize(7);
  doc.setFont("roboto", "regular");
  doc.text("(Authorised Signatory)", 166, 270);
}

function fillInNote(doc: jsPDF, newDebitCreditNote: DebitCreditNote) {
  doc.setFontSize(9.5);
  doc.setFont("roboto", "bold");
  doc.text("Note :", 80, 257);
  doc.setFontSize(7);
  doc.setFont("roboto", "regular");
  const Notes = doc
    .setFontSize(7)
    .setFont("roboto", "regular")
    .splitTextToSize(`${newDebitCreditNote.restData.note}`, 52);
  doc.text(`${Notes[0]}`, 80, 263);
  if (Notes[1]) {
    doc.text(`${Notes[1]}`, 80, 267);
  }
  doc.setFontSize(9.5);
  doc.setFont("roboto", "bold");
}

function fillInItemTable(doc: jsPDF, newDebitCreditNote: DebitCreditNote) {
  fillInItemTableHeaders(doc);

  const wightShortageAmt = newDebitCreditNote.restData.weightShortage;
  const trashExpAmt = newDebitCreditNote.restData.trashExp;
  const sampleTestingAmt = newDebitCreditNote.restData.sampleTesting;
  const cashDiscountAmt = newDebitCreditNote.restData.cashDiscount;
  const tradeDiscountAmt = newDebitCreditNote.restData.tradeDiscount;
  const qualityAllowanceAmt = newDebitCreditNote.restData.qualityAllowance;
  const otherCostAmt = newDebitCreditNote.restData.otherCostValueField;
  const { otherCostNameField } = newDebitCreditNote.restData;

  const itemTitles = [    
    "WEIGHT SHORTAGE",
    "TRASH EXP",
    "SAMPLE-TESTING",
    "CASH DISCOUNT",
    "TRADE DISCOUNT",
    "QUALITY ALLOWANCE",
    otherCostNameField
  ];
  const itemAmounts = [    
    wightShortageAmt,
    trashExpAmt,
    sampleTestingAmt,
    cashDiscountAmt,
    tradeDiscountAmt,
    qualityAllowanceAmt,
    otherCostAmt,
  ];

  itemAmounts.filter(item => item !== 0).forEach((item, i) => {
    const index = itemAmounts.indexOf(item);
    fillInOneItemForTable(
      doc,
      newDebitCreditNote,
      item,
      183 + i * 5.5,
      i+1,
      itemTitles[index]
    );
  });

  doc.setFontSize(11);
  doc.setFont("roboto", "bold");
  doc.text("Total", 79.5, 227);
}

function fillInOneItemForTable(
  doc: jsPDF,
  newDebitCreditNote: DebitCreditNote,
  amount: number,
  yCordinate: number,
  srNo: number,
  title: string
) {
  if (amount === 0) {
    return;
  }

  const GSTRate = newDebitCreditNote.restData.itemListGstRt;
  const stateCode = newDebitCreditNote.restData.buyerDtlsStcd;

  doc.setFont("roboto", "regular");
  doc.setFontSize(9.5);
  doc.text(`${srNo}`, 22, yCordinate);
  doc.text(title, 51, yCordinate, { align: "center" });
  doc.setFontSize(8.5);
  const { itemListHsnCd } = newDebitCreditNote.restData;
  doc.text(`${itemListHsnCd}`, 83.5, yCordinate, { align: "center" });

  doc.text(`${amount}`, 102.5, yCordinate, { align: "center" });
  const { itemListGstRt } = newDebitCreditNote.restData;
  doc.text(`${itemListGstRt}`, 119, yCordinate, { align: "center" });

  if (stateCode === "24") {
    // CGST
    doc.text(`${C_SGST(amount, GSTRate)}`, 132.5, yCordinate, {
      align: "center",
    });
    //SGST
    doc.text(`${C_SGST(amount, GSTRate)}`, 147.5, yCordinate, {
      align: "center",
    });
    //IGST
    doc.text(`${0}`, 162.5, yCordinate, { align: "center" });
  } else if (stateCode !== "24") {
    //CGST
    doc.text(`${0}`, 132.5, yCordinate, { align: "center" });
    //SGST
    doc.text(`${0}`, 147.5, yCordinate, { align: "center" });
    //IGST
    doc.text(`${IGST(amount, GSTRate)}`, 162.5, yCordinate, {
      align: "center",
    });
  }
  //Total of Each
  doc.text(
    `${netAmount(amount, C_SGST(amount, GSTRate), C_SGST(amount, GSTRate), 0)}`,
    181,
    yCordinate,

    { align: "center" }
  );
}

function fillInItemTableHeaders(doc: jsPDF) {
  doc.setFont("roboto", "medium");
  doc.text("Sr.", 20.5, 170);
  doc.text("Account Name", 39, 170);
  const hsnCodeLine = doc
    .setFontSize(9.5)
    .setFont("roboto", "medium")
    .splitTextToSize("HSN/SAC Code", 18);
  doc.text(`${hsnCodeLine[0]}`, 76.5, 167.5);
  doc.text(`${hsnCodeLine[1]}`, 80, 174);
  const taxableAmountLine = doc
    .setFontSize(9.5)
    .setFont("roboto", "medium")
    .splitTextToSize("Taxable Amount", 18);
  doc.text(`${taxableAmountLine[0]}`, 96.5, 167.5);
  doc.text(`${taxableAmountLine[1]}`, 96.5, 174);
  const gstPercentageLine = doc
    .setFontSize(9.5)
    .setFont("roboto", "medium")
    .splitTextToSize("GST %", 8);
  doc.text(`${gstPercentageLine[0]}`, 115.5, 167.5);
  doc.text(`${gstPercentageLine[1]}`, 117.5, 174);
  doc.text("Tax Amount", 139.5, 165.5);
  doc.text("CGST", 128, 174.5);
  doc.text("SGST", 143, 174.5);
  doc.text("IGST", 159, 174.5);
  const netAmountLine = doc
    .setFontSize(9.5)
    .setFont("roboto", "medium")
    .splitTextToSize("Net Amount", 12);
  doc.text(`${netAmountLine[0]}`, 178.5, 167.5);
  doc.text(`${netAmountLine[1]}`, 175.5, 174);
}

function fillInReasonFor(doc: jsPDF) {
  doc.setFont("roboto", "regular");
  doc.text("Reason For", 127.5, 154.5);
  doc.text(":", 147, 154.5);
}

function fillInEInvoiceDetails(doc: jsPDF, newDebitCreditNote: DebitCreditNote) {
  if (
    newDebitCreditNote.irn &&
    newDebitCreditNote.ackNo &&
    newDebitCreditNote.ackDt &&
    newDebitCreditNote.qrCodeImageUrl
  ) {
    doc.setFontSize(9.5);
    doc.setFont("roboto", "medium");
    doc.text("IRN:", 20, 95.5);
    const irnTextLine = doc
      .setFont("roboto", "regular")
      .setFontSize(8)
      .splitTextToSize(`${newDebitCreditNote.irn}`, 54.5);
    doc.text(irnTextLine, 28, 94);

    doc.setFontSize(9.5);
    doc.setFont("roboto", "medium");
    doc.text("Ack No:", 88, 95.5);
    doc.setFont("roboto", "regular");
    doc.setFontSize(9);
    doc.text(`${newDebitCreditNote.ackNo}`, 102, 95.5);

    doc.setFontSize(9.5);
    doc.setFont("roboto", "medium");
    doc.text("Ack Date:", 135, 95.5);
    doc.setFont("roboto", "regular");
    doc.setFontSize(9);
    const ackDate = new Date(newDebitCreditNote.ackDt);
    doc.text(
      `${ackDate.getDate()}/${
        ackDate.getMonth() + 1
      }/${ackDate.getFullYear()}, ${ConvertNumberToTwoDigitString(
        ackDate.getHours()
      )}:${ConvertNumberToTwoDigitString(ackDate.getMinutes())}`,
      152,
      95.5
    );

    doc.addImage(
      `${newDebitCreditNote.qrCodeImageUrl}`,
      "PNG",
      130,
      27,
      56,
      56
    );
  }
}

function fillInHeaderTexts(doc: jsPDF, newDebitCreditNote: DebitCreditNote) {
  const ourCompanyData = getOurCompanyData(
    newDebitCreditNote.restData.companyName
  );
  doc.setFontSize(20);
  doc.setFont("roboto", "bold");
  if (newDebitCreditNote.restData.docDtlsTyp === "CRN") {
    doc.text("CREDIT NOTE", 51.5, 34);
  } else if (newDebitCreditNote.restData.docDtlsTyp === "DBN") {
    doc.text("DEBIT NOTE", 51.5, 34);
  }
  doc.line(38.5, 37.5, 107.5, 37.5);

  doc.setFontSize(18.5);
  doc.setFont("roboto", "medium");
  doc.text(`${ourCompanyData.fromTrdName}`, 72.5, 46, { align: "center" });

  doc.setFontSize(9);
  doc.setFont("roboto", "medium");
  doc.text("COTTON MERCHANT", 57, 54);

  doc.setFontSize(8);
  doc.setFont("roboto", "regular");
  doc.text(`${ourCompanyData.fromAddr1}, ${ourCompanyData.fromAddr2}`, 75, 60, {
    align: "center",
  });
  doc.text(`${ourCompanyData.location} - ${ourCompanyData.pincode}`, 60, 64);

  doc.setFontSize(8);
  doc.setFont("roboto", "regular");
  doc.text("EMAIL:", 28.5, 69.2);
  doc.text("PHONE(o):", 75, 69.2);
  doc.setFont("roboto", "bold");
  doc.text("ajdalal0021@yahoo.com", 40, 69);
  doc.text("079-48004717/40021535", 90, 69.2);

  doc.setFontSize(9);
  doc.setFont("roboto", "regular");
  doc.text("GSTIN NO:", 53, 76.5);
  doc.setFont("roboto", "bold");
  doc.text(`${ourCompanyData.fromGstin}`, 70, 76.5);
}

function createTableLineSkeleton(doc: jsPDF) {
  doc.setFillColor(238, 238, 238);
  doc.setDrawColor(86, 86, 86);
  doc.rect(18.5, 20, 174, 80, "FD");
  doc.setFillColor(255, 255, 255);
  doc.rect(18.5, 100, 174, 8, "FD");

  doc.line(18.5, 108, 18.5, 160);
  doc.line(18.5, 160, 192.5, 160);
  doc.line(192.5, 160, 192.5, 108);
  doc.line(125, 108, 125, 160);
  doc.line(125, 148, 192.5, 148);
  doc.setFillColor(238, 238, 238);
  doc.rect(125, 108, 67.5, 40, "FD");

  doc.line(18.5, 160, 18.5, 272);
  doc.line(18.5, 272, 192.5, 272);
  doc.line(192.5, 272, 192.5, 160);
  doc.line(18.5, 178, 192.5, 178);
  doc.line(18.5, 220, 192.5, 220);
  doc.line(18.5, 232, 192.5, 232);
  doc.line(18.5, 252, 192.5, 252);

  doc.line(26.5, 160, 26.5, 220);
  doc.line(75, 160, 75, 220);
  doc.line(92.5, 160, 92.5, 220);
  doc.line(112.5, 160, 112.5, 220);
  doc.line(125, 160, 125, 220);
  doc.line(140, 169, 140, 220);
  doc.line(155, 169, 155, 220);
  doc.line(170, 160, 170, 220);
  doc.line(125, 169, 170, 169);

  doc.setFillColor(238, 238, 238);
  doc.rect(18.5, 220, 174, 12, "FD");

  doc.line(140, 232, 140, 252);
  doc.line(140, 242, 192.5, 242);
  doc.setFillColor(238, 238, 238);
  doc.rect(140, 242, 52.5, 10, "FD");

  doc.line(76.5, 252, 76.5, 272);
  doc.line(134.5, 252, 134.5, 272);
}

function importRobotoFontFile(doc: jsPDF) {
  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegularData);
  doc.addFont("Roboto-Regular.ttf", "roboto", "regular");
  doc.addFileToVFS("Roboto-Bold.ttf", robotoBoldData);
  doc.addFont("Roboto-Bold.ttf", "roboto", "bold");
  doc.addFileToVFS("Roboto-Italic.ttf", robotoItalicData);
  doc.addFont("Roboto-Italic.ttf", "roboto", "italic");
  doc.addFileToVFS("Roboto-Bold-Italic.ttf", robotoBoldItalicData);
  doc.addFont("Roboto-Bold-Italic.ttf", "roboto", "bold-italic");
  doc.addFileToVFS("Roboto-Medium.ttf", robotoBoldData);
  doc.addFont("Roboto-Medium.ttf", "roboto", "medium");
}

function fillInDebitCreditNoteNo(
  doc: jsPDF,
  newDebitCreditNote: DebitCreditNote
) {
  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  if (newDebitCreditNote.restData.docDtlsTyp === "CRN") {
    doc.text("Credit Note No.", 127.5, 116.5);
  }
  if (newDebitCreditNote.restData.docDtlsTyp === "DBN") {
    doc.text("Debit Note No.", 127.5, 116.5);
  }
  doc.text(":", 157.5, 116.5);
  doc.setFont("roboto", "regular");
  doc.text(`${newDebitCreditNote.restData.docDtlsNo}`, 162.5, 116.5);
}

function fillInDebitCreditNoteDate(
  doc: jsPDF,
  newDebitCreditNote: DebitCreditNote
) {
  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  if (newDebitCreditNote.restData.docDtlsTyp === "CRN") {
    doc.text("Credit Note Date", 127.5, 124.5);
  }
  if (newDebitCreditNote.restData.docDtlsTyp === "DBN") {
    doc.text("Debit Note Date", 127.5, 124.5);
  }
  doc.text(":", 157.5, 124.5);

  const debitCreditNoteDate = new Date(newDebitCreditNote.restData.docDtlsDt);

  doc.setFont("roboto", "regular");
  doc.text(
    `${debitCreditNoteDate.getDate()}/${
      debitCreditNoteDate.getMonth() + 1
    }/${debitCreditNoteDate.getFullYear()}`,
    162.5,
    124.5
  );
}

function fillInOriginalBillNo(doc: jsPDF, newDebitCreditNote: DebitCreditNote) {
  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  doc.text("Original Bill No.", 127.5, 132.5);
  doc.text(":", 157.5, 132.5);
  doc.setFont("roboto", "regular");
  doc.text(`${newDebitCreditNote.restData.precDocDtlsInvNo}`, 162.5, 132.5);
}

function fillInOriginalBillDate(
  doc: jsPDF,
  newDebitCreditNote: DebitCreditNote
) {
  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  doc.text("Original Bill Date", 127.5, 140.5);
  doc.text(":", 157.5, 140.5);

  const invoiceDate = new Date(newDebitCreditNote.restData.precDocDtlsInvDt);

  doc.setFont("roboto", "regular");
  doc.text(
    `${invoiceDate.getDate()}/${
      invoiceDate.getMonth() + 1
    }/${invoiceDate.getFullYear()}`,
    162.5,
    140.5
  );
}

function fillBuyerOrSellerDetails(
  doc: jsPDF,
  newDebitCreditNote: DebitCreditNote
) {
  doc.text("M/s.", 21, 116);
  doc.text(":", 28.5, 116);
  doc.setFontSize(10);
  doc.setFont("roboto", "bold");
  doc.text(`${newDebitCreditNote.restData.buyerDtlsLglNm}`, 32, 116);

  doc.setFontSize(9.5);
  doc.setFont("roboto", "regular");
  const buyerAddressLines = doc
    .setFontSize(9.5)
    .setFont("roboto", "regular")
    .splitTextToSize(
      `${newDebitCreditNote.restData.buyerDtlsAddr1} ${
        newDebitCreditNote.restData.buyerDtlsAddr2
          ? newDebitCreditNote.restData.buyerDtlsAddr2
          : ""
      }`,
      86
    );
  doc.text(`${buyerAddressLines[0]}`, 32, 124);
  if (buyerAddressLines[1]) {
    doc.text(`${buyerAddressLines[1]}`, 32, 130);
  }
  const { buyerDtlsLoc } = newDebitCreditNote.restData;
  doc.text(
    `${buyerDtlsLoc} - ${newDebitCreditNote.restData.buyerDtlsPin}`,
    32,
    136
  );

  const stateCode = newDebitCreditNote.restData.buyerDtlsStcd;

  const stateName = stateCodeData[stateCode];
  doc.text("Place of Supply :", 21, 144);
  doc.text(`${stateCode} - ${stateName}`, 50, 144);
  doc.text("GSTIN No. :", 21, 152);
  doc.setFont("roboto", "medium");
  doc.text(`${newDebitCreditNote.restData.buyerDtlsGstin}`, 40, 152);
}

function fillInTermsAndConditions(doc: jsPDF) {
  doc.setFontSize(9.5);
  doc.text("Terms & Conditions :", 22, 257);
  doc.setFontSize(7);
  doc.setFont("roboto", "regular");
  doc.text("1. Subjected to 'AHMEDABAD' Jurisdiction Only", 22, 263);
  doc.text("2. Goods once sold will not be taken back", 22, 267);
}

function fillInTotalAmount(doc: jsPDF, newDebitCreditNote: DebitCreditNote) {
  const stateCode = newDebitCreditNote.restData.buyerDtlsStcd;

  if (stateCode === "24") {
    const cgstTotAmt = getTotalCSGST(newDebitCreditNote);
    doc.setFontSize(8.5);
    doc.setFont("roboto", "regular");
    doc.text(`${cgstTotAmt.toFixed(2)}`, 132.5, 227, { align: "center" });

    const sgstTotAmt = getTotalCSGST(newDebitCreditNote);

    doc.text(`${sgstTotAmt.toFixed(2)}`, 147.5, 227, { align: "center" });

    const igstTotAmt = 0;

    doc.text(`${igstTotAmt}`, 162.5, 227, { align: "center" });

    const totalPreTaxAmt = getTotalPreTaxAmount(newDebitCreditNote);

    doc.text(`${totalPreTaxAmt}`, 102.5, 227, { align: "center" });

    const totalAmount =
      Math.round((totalPreTaxAmt + cgstTotAmt + sgstTotAmt) * 100) / 100;
    const rndOffTotalAmount = Math.round(totalAmount);

    doc.text(`${totalAmount}`, 181, 227, { align: "center" });
    doc.setFontSize(10);

    doc.text(`${rndOffTotalAmount}`, 188, 248, { align: "right" });

    const totalAmountinWords = Math.round(totalAmount);
    const totalGSTinWords = Math.round(cgstTotAmt + sgstTotAmt);
    doc.setFontSize(8);
    doc.setFont("roboto", "regular");
    doc.text(`${`${Digit2Word(totalAmountinWords)} Rupees Only`}`, 42, 247);
    doc.text(`${`${Digit2Word(totalGSTinWords)} Rupees Only`}`, 42, 239);
  } else if (stateCode !== "24") {
    const igstTotAmt = getTotalIGST(newDebitCreditNote);
    doc.setFontSize(8.5);
    doc.setFont("roboto", "regular");

    doc.text(`${igstTotAmt}`, 162.5, 227, { align: "center" });

    const cgstTotAmt = 0;

    doc.text(`${cgstTotAmt}`, 132.5, 227, { align: "center" });

    const sgstTotAmt = 0;

    doc.text(`${sgstTotAmt}`, 147.5, 227, { align: "center" });

    const totalPreTaxAmt = getTotalPreTaxAmount(newDebitCreditNote);

    doc.text(`${totalPreTaxAmt}`, 102.5, 227, { align: "center" });

    const totalAmount = Math.round((totalPreTaxAmt + igstTotAmt) * 100) / 100;
    const rndOffTotalAmount = Math.round(totalAmount);

    doc.text(`${totalAmount}`, 181, 227, { align: "center" });
    doc.setFontSize(10);

    doc.text(`${Math.round(rndOffTotalAmount)}`, 188, 248, { align: "right" });

    doc.setFontSize(8);
    doc.setFont("roboto", "regular");
    doc.text(`${`${Digit2Word(totalAmount)} Rupees Only`}`, 42, 247);
    doc.text(`${`${Digit2Word(igstTotAmt)} Rupees Only`}`, 42, 239);
  }
  doc.setFontSize(9.5);
  doc.setFont("roboto", "medium");
  doc.text("Total GST :", 22, 239);
  doc.text("Bill Amount :", 22, 247);

  doc.setFontSize(10.5);
  doc.setFont("roboto", "bold");
  doc.text("Grand Total :", 143, 248);
  doc.setFontSize(11);
  doc.setFont("roboto", "bold");
  doc.text("Total", 79.5, 227);
}

const C_SGST = (amount: number, GSTRate: number) => {
  return Math.round(amount * GSTRate) / 200;
};

const IGST = (amount: number, GSTRate: number) => {
  return Math.round(amount * GSTRate) / 100;
};

const getTotalIGST = (newDebitCreditNote: DebitCreditNote) => {
  const wightShortageAmt = newDebitCreditNote.restData.weightShortage;
  const trashExpAmt = newDebitCreditNote.restData.trashExp;
  const sampleTestingAmt = newDebitCreditNote.restData.sampleTesting;
  const cashDiscountAmt = newDebitCreditNote.restData.cashDiscount;
  const tradeDiscountAmt = newDebitCreditNote.restData.tradeDiscount;
  const qualityAllowanceAmt = newDebitCreditNote.restData.qualityAllowance;
  const otherCostAmt = newDebitCreditNote.restData.otherCostValueField;
  const GSTRate = newDebitCreditNote.restData.itemListGstRt;

  return (
    IGST(wightShortageAmt, GSTRate) +
    IGST(trashExpAmt, GSTRate) +
    IGST(sampleTestingAmt, GSTRate) +
    IGST(cashDiscountAmt, GSTRate) +
    IGST(tradeDiscountAmt, GSTRate) +
    IGST(qualityAllowanceAmt, GSTRate) +
    IGST(otherCostAmt, GSTRate)
  );
};

const getTotalCSGST = (newDebitCreditNote: DebitCreditNote) => {
  const wightShortageAmt = newDebitCreditNote.restData.weightShortage;
  const trashExpAmt = newDebitCreditNote.restData.trashExp;
  const sampleTestingAmt = newDebitCreditNote.restData.sampleTesting;
  const cashDiscountAmt = newDebitCreditNote.restData.cashDiscount;
  const tradeDiscountAmt = newDebitCreditNote.restData.tradeDiscount;
  const qualityAllowanceAmt = newDebitCreditNote.restData.qualityAllowance;
  const otherCostAmt = newDebitCreditNote.restData.otherCostValueField;
  const GSTRate = newDebitCreditNote.restData.itemListGstRt;

  return (
    C_SGST(wightShortageAmt, GSTRate) +
    C_SGST(trashExpAmt, GSTRate) +
    C_SGST(sampleTestingAmt, GSTRate) +
    C_SGST(cashDiscountAmt, GSTRate) +
    C_SGST(tradeDiscountAmt, GSTRate) +
    C_SGST(qualityAllowanceAmt, GSTRate) +
    C_SGST(otherCostAmt, GSTRate)
  );
};

const getTotalPreTaxAmount = (newDebitCreditNote: DebitCreditNote) => {
  const wightShortageAmt = newDebitCreditNote.restData.weightShortage;
  const trashExpAmt = newDebitCreditNote.restData.trashExp;
  const sampleTestingAmt = newDebitCreditNote.restData.sampleTesting;
  const cashDiscountAmt = newDebitCreditNote.restData.cashDiscount;
  const tradeDiscountAmt = newDebitCreditNote.restData.tradeDiscount;
  const qualityAllowanceAmt = newDebitCreditNote.restData.qualityAllowance;
  const otherCostAmt = newDebitCreditNote.restData.otherCostValueField;

  return (
    wightShortageAmt +
    trashExpAmt +
    sampleTestingAmt +
    cashDiscountAmt +
    tradeDiscountAmt +
    qualityAllowanceAmt +
    otherCostAmt
  );
};

const netAmount = (
  amount: number,
  sgst: number,
  cgst: number,
  igst: number
) => {
  const total = amount + sgst + cgst + igst;
  return Math.round(total * 100) / 100;
};
