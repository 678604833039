import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { insuranceAtom } from "../../states/invoicesState";

function Insurance() {
    const [insurance, setInsurance] = useAtom(insuranceAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Insurance"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={insurance}
            onChange={(e) => {
              setInsurance(e.target.value);
            }}
          />
    );
}

export default Insurance;