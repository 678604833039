import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FailedInvoiceData } from "../models";
import Grid from "@mui/material/Unstable_Grid2";
import { handleFetchFailedInvoices } from "../helpers";
import FailedDialogBox from "./FailedDialogBox";
import { selectedFailedInvoiceAtom, showDialogBoxAtom } from "../states/invoicesState";
import { useAtom } from "jotai";

function FailedInvoiceList() {
  const [failedInvoices, setFailedInvoices] = useState<FailedInvoiceData[]>([]);
  const [,setShowDialogBox] = useAtom(showDialogBoxAtom);
  const [, setSelectedFailedInvoice] = useAtom(selectedFailedInvoiceAtom);

  useEffect(() => {
    (async () => {
      const FailedInvoicesData = await handleFetchFailedInvoices();
      setFailedInvoices(FailedInvoicesData);
    })();
  }, []);

  function fetchMoreFailedInvoiceData() {
    // setFetching(true);
    setTimeout(() => {
      console.log("fetching more failed");
      // setFailedInvoices([...failedInvoices, ...Array.from({ length: 20 })]);
      // setFetching(false);
    }, 1500);
  }

  function handleSingleClick(failedInvoice: FailedInvoiceData) {
    setSelectedFailedInvoice(failedInvoice);
    setShowDialogBox(true);
  }

  return (
    <List
      id="failed-invoice-list"
      style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
    >
      <InfiniteScroll
        dataLength={failedInvoices.length}
        next={() => {
          // if (!fetching) {
          fetchMoreFailedInvoiceData();
          // }
        }}
        hasMore={true}
        scrollableTarget="failed-invoice-list"
        loader={
          <div>
            <LinearProgress />
          </div>
        }
        endMessage={<p>No more data to load.</p>}
      >
        {failedInvoices.map((failedInvoice, index) => [
          <ListItemButton key={"list-item" + index} onClick={() => {handleSingleClick(failedInvoice)}}>
            <Grid container width="100%">
                <Grid xs={12} sm={6} md={3} className="draft-invoice-invoice-date">
                  {failedInvoice.companyName}
                </Grid>
                <Grid xs={12} sm={6} md={3} className="draft-invoice-invoice-number">
                  {failedInvoice.buyerName}
                </Grid>
                <Grid xs={12} sm={6} md={3} className="draft-invoice-buyer-name">
                  {failedInvoice.contractNumber}
                </Grid>
                <Grid xs={12} sm={6} md={3} className="draft-invoice-company-name">
                  {failedInvoice.quantity}
                </Grid>
              </Grid>
          </ListItemButton>,
          <Divider
            key={"list-item-divider-" + index}
            variant="middle"
            component="li"
          />,
        ])}
      </InfiniteScroll>
      <FailedDialogBox />
    </List>
  );
}

export default FailedInvoiceList;
