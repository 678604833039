import { Company } from "../../../models";

export type DebitCreditNoteRestData = {
  buyerDtlsAddr1: string;
  buyerDtlsAddr2: string;
  buyerDtlsGstin: string;
  buyerDtlsLglNm: string;
  buyerDtlsLoc: string;
  buyerDtlsPin: number;
  buyerDtlsPos: string;
  buyerDtlsStcd: string;
  buyerDtlsTrdNm: string;
  cashDiscount: number;
  companyName: Company;
  docDtlsDt: string;
  docDtlsNo: string;
  docDtlsTyp: DebitCreditNoteType;
  itemListAssAmt: number;
  itemListCesAmt: number;
  itemListCesNonAdvlAmt: number;
  itemListCesRt: number;
  itemListCgstAmt: number;
  itemListDiscount: number;
  itemListFreeQty: number;
  itemListGstRt: number;
  itemListHsnCd: string;
  itemListIgstAmt: number;
  itemListIsServc: string;
  itemListOthChrg: number;
  itemListPrdDesc: string;
  itemListQty: number;
  itemListSgstAmt: number;
  itemListSlNo: string;
  itemListStateCesAmt: number;
  itemListStateCesNonAdvlAmt: number;
  itemListStateCesRt: number;
  itemListTotAmt: number;
  itemListTotItemVal: number;
  itemListUnit: string;
  itemListUnitPrice: number;
  note: string;
  otherCostNameField: string;
  otherCostValueField: number;
  precDocDtlsInvDt: string;
  precDocDtlsInvNo: string;
  qualityAllowance: number;
  sampleTesting: number;
  sellerDtlsAddr1: string;
  sellerDtlsAddr2: string;
  sellerDtlsGstin: string;
  sellerDtlsLglNm: string;
  sellerDtlsLoc: string;
  sellerDtlsPin: number;
  sellerDtlsStcd: string;
  sellerDtlsTrdNm: string;
  tradeDiscount: number;
  tranDtlsEcmGstin: string | null;
  tranDtlsRegRev: string;
  tranDtlsSupTyp: string;
  tranDtlsTaxSch: string;
  trashExp: number;
  valDtlsAssValue: number;
  valDtlsCesVal: number;
  valDtlsCgst: number;
  valDtlsDiscount: number;
  valDtlsIgst: number;
  valDtlsOthChrg: number;
  valDtlsRndOffAmt: number;
  valDtlsSgst: number;
  valDtlsStCesVal: number;
  valDtlsTotInvVal: number;
  valDtlsTotInvValFc: number;
  version: string;
  weightShortage: number;
};

export type DebitCreditNote = {
  ackDt: string;
  ackNo: string;
  irn: string;
  qrCodeImageUrl: string;
  restData: DebitCreditNoteRestData
};

export enum DebitCreditNoteType {
  DebitNote = "DBN",
  CreditNote = "CRN",
  EMPTY = ""
}
