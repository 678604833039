import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import { useAtom } from "jotai";
import {
  showDialogBoxAtom,
  selectedDebitCreditNoteAtom,
} from "../states/DebitCreditNotesState";
import Dialog from "@mui/material/Dialog";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { displayDebitCreditNotePDF } from "../helpers/displayDebitCreditNotePDF";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DebitNoteDialogBox() {
  const [showDialogBox, setShowDialogBox] = useAtom(showDialogBoxAtom);
  const [selectedDebitCreditNote] = useAtom(selectedDebitCreditNoteAtom);
  const [pdfDataURL, setPDFDataURL] = useState<string>("");

  const handleClose = () => {
    setShowDialogBox(false);
  };

    useEffect(()=>{
      (async () => {
        if (selectedDebitCreditNote) {
          const dataURL = await displayDebitCreditNotePDF(selectedDebitCreditNote);
          setPDFDataURL(dataURL);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDebitCreditNote])

    const docType = selectedDebitCreditNote?.restData?.docDtlsTyp;

  return (
    <Dialog
      fullScreen
      open={showDialogBox}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {
              docType === "DBN" ? (
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Debit Note PDF
                </Typography>
              ) : (
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Credit Note PDF
                </Typography>
              )
            }
            </Toolbar>
        </AppBar>
        {pdfDataURL && <embed className="debitnotepdf" type="application/pdf" src={pdfDataURL} style={{overflow: "auto", width: "100%", height: "100%"}}></embed>}
      </Dialog>
  );
}
