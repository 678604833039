import { Grid, Typography } from "@mui/material";
import ItemListGstRt from "../ItemList/GstRt";
import ItemListHsnCd from "../ItemList/HsnCd";
import ItemListIsServc from "../ItemList/IsServc";
import ItemListPrdDesc from "../ItemList/PrdDesc";
import ItemListQty from "../ItemList/Qty";
import ItemListUnit from "../ItemList/Unit";

function ItemList() {

    return (
        <Grid container>
            <Typography>Item List</Typography>
        <hr className="full-width" />
        <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
          <ItemListPrdDesc />
        </Grid>
        <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
          <ItemListHsnCd />
        </Grid>
        <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
          <ItemListUnit />
        </Grid>
        <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
          <ItemListQty />
        </Grid>
        <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
          <ItemListGstRt />
        </Grid>
        <Grid xs={12} sm={6} md={2} padding="20px" className="invoice-date">
          <ItemListIsServc />
        </Grid>
        </Grid>
    );
}

export default ItemList;