import { useAtom } from "jotai";
import { buyerDtlsTrdNmAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsTrdNm() {
    const [buyerDtlsTrdNm, setBuyerDtlsTrdNm] = useAtom(buyerDtlsTrdNmAtom);

    return <TextField
    id="outlined-basic"
    label="TrdNm"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsTrdNm}
    onChange={(e) => {
        setBuyerDtlsTrdNm(e.target.value);
    }}
  />;
};

export default BuyerDtlsTrdNm;