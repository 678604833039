import { useAtom } from "jotai";
import { materialAtom } from "../../states/invoicesState";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function Material() {
    const [material, setMaterial] = useAtom(materialAtom);

    return (
        <FormControl fullWidth>
            <InputLabel id="material-dropdown-label">
              Material
            </InputLabel>
            <Select
              fullWidth
              labelId="material-dropdown-label"
              id="material-dropdown"
              label="Company"
              value={material}
              onChange={(e) => {
                setMaterial(e.target.value);
              }}
            >
              <MenuItem value="COTTON">
                <em>COTTON</em>
              </MenuItem>
              <MenuItem value="YARN">
                <em>YARN</em>
              </MenuItem>
            </Select>
          </FormControl>
    );
    
}

export default Material;