import { useAtom } from "jotai";
import { contractNumberAtom } from "../../states/invoicesState"
import TextField from "@mui/material/TextField";

function ContractNumber() {
    const [contractNumber, setContractNumber] = useAtom(contractNumberAtom);

    return <TextField
    id="outlined-basic"
    label="Contract No."
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={contractNumber}
    onChange={(e) => {
      setContractNumber(e.target.value);
    }}
  />;
}

export default ContractNumber;