import { Grid } from "@mui/material";
import DropAddress from "../DropDetails/DropAddress";
import DropName from "../DropDetails/DropName";
import DropPinCode from "../DropDetails/DropPinCode";
import DropPlace from "../DropDetails/DropPlace";
import DropState from "../DropDetails/DropState";

function DropDetails() {
  return (
    <Grid container>
      <hr className="full-width" />

      <Grid xs={12} sm={6} md={6} padding="20px" className="drop-name">
        <DropName />
      </Grid>

      <Grid xs={12} sm={6} md={6} padding="20px" className="drop-address">
        <DropAddress />
      </Grid>

      <Grid xs={12} sm={6} md={4} padding="20px" className="drop-place">
        <DropPlace />
      </Grid>

      <Grid xs={12} sm={6} md={4} padding="20px" className="drop-pin-code">
        <DropPinCode />
      </Grid>

      <Grid xs={12} sm={6} md={4} padding="20px" className="drop-state">
        <DropState />
      </Grid>
    </Grid>
  );
}

export default DropDetails;
