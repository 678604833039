import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { dropStateAtom } from "../../states/invoicesState";

function DropState() {
    const [dropState, setDropState] = useAtom(dropStateAtom);

    return (
        <TextField
            id="outlined-basic"
            label="State"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={dropState}
            onChange={(e) => {
              setDropState(e.target.value);
            }}
          />
    );
}

export default DropState;