import axios from "axios";
import { LocalStateAction } from "../../../models";
import { getFirestore, collection, query, where, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { DebitCreditNoteType } from "../models";

export const handleCreateDebitCreditNote = async (
  newDebitCreditNote: any,
  setCreating: LocalStateAction<boolean>
) => {
  setCreating(true);
  try {
    await axios.post(
      "https://createdebitcreditnote-ouv53wblhq-uc.a.run.app",
      newDebitCreditNote
    );
    console.log("request body :", newDebitCreditNote);
  } catch (error) {
    console.log(error);
  }
  setCreating(false);
};

export const handleFetchDebitCreditNotes = async (docDtlsTyp: DebitCreditNoteType, startAfterInput = null) => {
  const db = getFirestore();
  const debitCreditNotesRef = collection(db, "DebitCreditNoteRecievedData");
  const debitCreditNotes: any[] = [];
  let q;
  if (startAfterInput === null) {
    q = query(debitCreditNotesRef, where("restData.docDtlsTyp", "==", docDtlsTyp), orderBy("ackDt", "desc"), limit(25));
  } else {
    q = query(
      debitCreditNotesRef,
      where("restData.docDtlsTyp", "==", docDtlsTyp),
      orderBy("ackDt", "desc"),
      startAfter(startAfterInput),
      limit(25)
    );
  }

  const querySnapshot = await getDocs(q);
  const snapshots: any[] = [];
  querySnapshot.forEach((doc: any) => {
    snapshots.push(doc);
    debitCreditNotes.push(doc.data());
  });
  return [debitCreditNotes, snapshots[snapshots.length - 1]];
};

