import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { pickUpPlaceAtom } from "../../states/invoicesState";

function PickUpPlace() {
    const [pickUpPlace, setPickUpPlace] = useAtom(pickUpPlaceAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Place"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pickUpPlace}
            onChange={(e) => {
              setPickUpPlace(e.target.value);
            }}
          />
    );
}

export default PickUpPlace;