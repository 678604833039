import { useAtom } from "jotai";
import { companyAtom } from "../../states/invoicesState";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Company() {
    const [company, setCompany] = useAtom(companyAtom);

    return(
        <FormControl fullWidth>
            <InputLabel id="company-dropdown-label">
              Company
            </InputLabel>
            <Select
              fullWidth
              labelId="company-dropdown-label"
              id="company-dropdown"
              label="Company"
              value={company}
              onChange={(e) => {
                setCompany(e.target.value);
              }}
            >
              <MenuItem value="AMI TRADERS">
                <em>AMI TRADERS</em>
              </MenuItem>
              <MenuItem value="HARSH COTTON COMPANY">
                <em>HARSH COTTON COMPANY</em>
              </MenuItem>
              <MenuItem value="HARDAM COTTON LLP">
                <em>HARDAM COTTON LLP</em>
              </MenuItem>
              <MenuItem value="S AJAYKUMAR & CO.">
                <em>S AJAYKUMAR & CO.</em>
              </MenuItem>
              <MenuItem value="J.J.ENTERPRISE">
                <em>J.J.ENTERPRISE</em>
              </MenuItem>
            </Select>
          </FormControl>
    );
}

export default Company;