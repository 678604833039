import { useAtom } from "jotai";
import { pressNumber1Atom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function PressNumber1() {
    const [pressNumber1, setPressNumber1] = useAtom(pressNumber1Atom);

    return (
        <TextField
        id="outlined-basic"
        label="Press No. 1"
        variant="outlined"
        autoComplete="off"
        fullWidth
        value={pressNumber1}
        onChange={(e) => {
          setPressNumber1(e.target.value);
        }}
      /> 
    );
}

export default PressNumber1;