import { useAtom } from "jotai";
import { buyerDtlsLglNmAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsLglNm() {
    const [buyerDtlsLglNm, setBuyerDtlsLglNm] = useAtom(buyerDtlsLglNmAtom);

    return <TextField
    id="outlined-basic"
    label="LglNm"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsLglNm}
    onChange={(e) => {
        setBuyerDtlsLglNm(e.target.value);
    }}
  />;
};

export default BuyerDtlsLglNm;