import { useAtom } from "jotai";
import { documentNumberAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function DocumentNumber() {
    const [documentNumber, setDocumentNumber] = useAtom(documentNumberAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Document No."
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={documentNumber}
            onChange={(e) => {
              setDocumentNumber(e.target.value);
            }}
          />
    );
    
}

export default DocumentNumber;