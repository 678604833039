import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { tareAtom } from "../../states/invoicesState";

function Tare() {
    const [tare, setTare] = useAtom(tareAtom);

    return (
    <TextField
        id="outlined-basic"
        label="Tare"
        variant="outlined"
        autoComplete="off"
        fullWidth
        type="number"
        value={tare}
        onChange={(e) => {
          setTare(e.target.value);
        }}
      />);
}

export default Tare;