import { useAtom } from "jotai";
import { TCSAtom } from "../../states/invoicesState";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function TCS() {
    const [tcs, setTcs] = useAtom(TCSAtom);

    return (
        <FormControl fullWidth>
            <InputLabel id="TCS-dropdown-label">TCS</InputLabel>
            <Select
              fullWidth
              labelId="TCS-dropdown-label"
              id="TCS-dropdown"
              label="TCS"
              value={tcs}
              onChange={(e) => {
                setTcs(e.target.value);
              }}
            >
              <MenuItem value="Exclude">
                <em>Exclude</em>
              </MenuItem>
              <MenuItem value="Include">
                <em>Include</em>
              </MenuItem>
            </Select>
          </FormControl>
    );
}

export default TCS;