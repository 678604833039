import Button from "@mui/material/Button";
import { handleLogout } from "../../features/authentication";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

function LogOutButton() {
  return (
    <Button color="inherit" onClick={handleLogout}>
      <PowerSettingsNewIcon />
    </Button>
  );
}

export default LogOutButton;
