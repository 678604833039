import { useAtom } from "jotai";
import { buyerDtlsAddr2Atom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsAddr2() {
    const [buyerDtlsAddr2, setBuyerDtlsAddr2] = useAtom(buyerDtlsAddr2Atom);

    return <TextField
    id="outlined-basic"
    label="Addr2"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsAddr2 ? buyerDtlsAddr2 : ""}
    onChange={(e) => {
        setBuyerDtlsAddr2(e.target.value);
    }}
  />;
};

export default BuyerDtlsAddr2;