import { useAtom } from "jotai";
import { sellerDtlsAddr2Atom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsAddr2() {
    const [sellerDtlsAddr2, setSellerDtlsAddr2] = useAtom(sellerDtlsAddr2Atom);

    return <TextField
    id="outlined-basic"
    label="Addr2"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsAddr2}
    onChange={(e) => {
        setSellerDtlsAddr2(e.target.value);
    }}
  />;
};

export default SellerDtlsAddr2;