import { useAtom } from "jotai";
import { otherCostNameFieldAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function OtherCost() {
    const [otherCostNameField, setOtherCostNameField] = useAtom(otherCostNameFieldAtom);

    return <TextField
    id="outlined-basic"
    label="Add Other Cost"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={otherCostNameField}
    onChange={(e) => {
        setOtherCostNameField(e.target.value);
    }}
  />;
};

export default OtherCost;