import { useAtom } from "jotai";
import { PrecDocDtlsInvDtAtom } from "../../states/DebitCreditNotesState";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";

function PrecDocDtlsInvDt() {
    const [precDocDtlsInvDt, setPrecDocDtlsInvDt] = useAtom(PrecDocDtlsInvDtAtom);

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer
      components={["DatePicker"]}
      sx={{ p: 0, width: "100%", overflow: "visible" }}
    >
      <DatePicker
        label="Invoice Date"
        slotProps={{ textField: { fullWidth: true } }}
        value={precDocDtlsInvDt}
        format="DD/MM/YYYY"
        onChange={(e: Dayjs | null) => {
            setPrecDocDtlsInvDt(e);
        }}
      />
    </DemoContainer>
  </LocalizationProvider>;;
};

export default PrecDocDtlsInvDt;