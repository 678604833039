import { Grid, Typography } from "@mui/material";
import SellerDtlsAddr1 from "../SellerDetails/Addr1";
import SellerDtlsAddr2 from "../SellerDetails/Addr2";
import SellerDtlsGstin from "../SellerDetails/Gstin";
import SellerDtlsLglNm from "../SellerDetails/LglNm";
import SellerDtlsLoc from "../SellerDetails/Loc";
import SellerDtlsPin from "../SellerDetails/Pin";
import SellerDtlsStcd from "../SellerDetails/Stcd";
import SellerDtlsTrdNm from "../SellerDetails/TrdNm";

function SellerDetails() {
  return (
    <Grid container>
      <Typography>Seller Details</Typography>
      <hr className="full-width" />
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsLglNm />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsTrdNm />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsGstin />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsStcd />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsAddr1 />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsAddr2 />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsLoc />
      </Grid>
      <Grid xs={12} sm={6} md={3} padding="20px" className="invoice-date">
        <SellerDtlsPin />
      </Grid>
      <hr className="full-width" />
    </Grid>
  );
}

export default SellerDetails;
