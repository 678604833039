import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAtom } from "jotai";
import { invoiceDateAtom } from "../../states/invoicesState"
import { Dayjs } from "dayjs";

function InvoiceDate() {
    const [invoiceDate, setIncoiceDate] = useAtom(invoiceDateAtom);

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DemoContainer
    components={["DatePicker"]}
    sx={{ p: 0, width: "100%", overflow: "visible" }}
  >
    <DatePicker
      label="Date"
      slotProps={{ textField: { fullWidth: true } }}
      value={invoiceDate}
      format="DD/MM/YYYY"
      onChange={(e: Dayjs | null) => {
        setIncoiceDate(e);
      }}
    />
  </DemoContainer>
</LocalizationProvider>;   
}

export default InvoiceDate;

