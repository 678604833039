import { FormEvent } from "react";
import FirebaseAuthService from "../../../firebase/auth";
import { LocalStateAction } from "../../../models";

export const handleLoginWithGoogle = async () => {
  try {
    await FirebaseAuthService.loginWithGoogle();
  } catch (error: any) {
    alert((error as Error).message);
  }
};

export const handleLogInWithEmailPassword = async (
  event: FormEvent,
  username: string,
  passcode: string,
  setUsername: LocalStateAction<string>,
  setPasscode: LocalStateAction<string>,
  setLoggingIn: LocalStateAction<boolean>
) => {
  event.preventDefault();

  setLoggingIn(true);
  try {
    await FirebaseAuthService.loginUser(username, passcode);
    setUsername("");
    setPasscode("");
  } catch (error: any) {
    alert((error as Error).message);
  }
  setLoggingIn(false);
};

export const handleLogout = () => {
  FirebaseAuthService.logoutUser();
};
