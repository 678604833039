import { useAtom } from "jotai";
import { sellerDtlsGstinAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SellerDtlsGstin() {
    const [sellerDtlsGstin, setSellerDtlsGstin] = useAtom(sellerDtlsGstinAtom);

    return <TextField
    id="outlined-basic"
    label="Gstin"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sellerDtlsGstin}
    onChange={(e) => {
        setSellerDtlsGstin(e.target.value);
    }}
  />;
};

export default SellerDtlsGstin;