import { useAtom } from "jotai";
import { otherCostValueFieldAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function OtherCost() {
    const [otherCostValueField, setOtherCostValueField] = useAtom(otherCostValueFieldAtom);

    return <TextField
    id="outlined-basic"
    label="Other Cost Value"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={otherCostValueField}
    onChange={(e) => {
        setOtherCostValueField(e.target.value);
    }}
  />;
};

export default OtherCost;