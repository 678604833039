import { Company, CompanyData } from "../models";

export const getOurCompanyData = (company: Company): CompanyData => {
  switch (company) {
    case Company.AMI:
      return {
        fromTrdName: Company.AMI,
        fromAddr1: "304  SUMANAL APPATMENT",
        fromAddr2: "NAVRANGPURA",
        location: "Ahmedabad",
        state: "GUJARAT",
        dispatchFromGSTIN: "24ACIPS6847B1ZI",
        dispatchFromTradeName: "AMI TRADERS",
        fromGstin: "24ACIPS6847B1ZI",
        pincode: 380009,
      };
    case Company.HARSH:
      return {
        fromTrdName: Company.HARSH,
        fromAddr1: "1, NIKUNJ APARTMENT, 4 KALPANA SOCIETY",
        fromAddr2: "NAVRANGPURA",
        location: "Ahmedabad",
        state: "GUJARAT",
        dispatchFromGSTIN: "24AAHPH7474G1ZN",
        dispatchFromTradeName: "HARSH COTTON COMPANY",
        fromGstin: "24AAHPH7474G1ZN",
        pincode: 380009,
      };
    case Company.HARDAM:
      return {
        fromTrdName: Company.HARDAM,
        fromAddr1: "1, NIKUNJ APARTMENT, 4 KALPANA SOCIETY",
        fromAddr2: "NAVRANGPURA",
        location: "Ahmedabad",
        state: "GUJARAT",
        dispatchFromGSTIN: "24AAPFH0445M1Z6",
        dispatchFromTradeName: "HARDAM COTTON LLP",
        fromGstin: "24AAPFH0445M1Z6",
        pincode: 380009,
      };
    case Company.JJ:
      return {
        fromTrdName: Company.JJ,
        fromAddr1: "1, NIKUNJ APARTMENT, 4 KALPANA SOCIETY",
        fromAddr2: "NAVRANGPURA",
        location: "Ahmedabad",
        state: "GUJARAT",
        dispatchFromGSTIN: "24BCPPS2842Q1ZS",
        dispatchFromTradeName: "J.J. ENTERPRISE",
        fromGstin: "24BCPPS2842Q1ZS",
        pincode: 380009,
      };
    default:
      return {
        fromTrdName: Company.EMPTY,
        fromAddr1: "",
        fromAddr2: "",
        location: "",
        state: "",
        dispatchFromGSTIN: "",
        dispatchFromTradeName: "",
        fromGstin: "",
        pincode: 0,
      };
  }
};
