import { useAtom } from "jotai";
import { buyerDtlsPinAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsPin() {
  const [buyerDtlsPin, setBuyerDtlsPin] = useAtom(buyerDtlsPinAtom);

  return (
    <TextField
      id="outlined-basic"
      label="Pin"
      variant="outlined"
      autoComplete="off"
      fullWidth
      value={buyerDtlsPin}
      onChange={(e) => {
        if (e.target.value) {
          setBuyerDtlsPin(parseFloat(e.target.value));
        } else {
          setBuyerDtlsPin(0);
        }
      }}
    />
  );
}

export default BuyerDtlsPin;
