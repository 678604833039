import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { quantityAtom } from "../../states/invoicesState";

function Quantity() {
    const [quantity, setQuantity] = useAtom(quantityAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Quantity"
            variant="outlined"
            autoComplete="off"
            fullWidth
            type="number"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
    );
}

export default Quantity;