import { useAtom } from "jotai";
import { sampleTestingAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function SampleTesting() {
    const [sampleTesting, setSampleTesting] = useAtom(sampleTestingAtom);

    return <TextField
    id="outlined-basic"
    label="Sample Testing"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={sampleTesting}
    onChange={(e) => {
        setSampleTesting(e.target.value);
    }}
  />;
};

export default SampleTesting;