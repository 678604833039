import { useAtom } from "jotai";
import { stationAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function Station() {
    const [station, setStation] = useAtom(stationAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Station"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={station}
            onChange={(e) => {
              setStation(e.target.value);
            }}
          />
    );
}

export default Station;