import { useAtom } from "jotai";
import { pickUpNameAtom } from "../../states/invoicesState";
import TextField from "@mui/material/TextField";

function PickUpName() {
    const [pickUpName, setPickUpName] = useAtom(pickUpNameAtom);

    return (
        <TextField
            id="outlined-basic"
            label="Pick Up Name"
            variant="outlined"
            autoComplete="off"
            fullWidth
            value={pickUpName}
            onChange={(e) => {
              setPickUpName(e.target.value);
            }}
          />
    );
}

export default PickUpName;