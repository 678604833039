import { useAtom } from "jotai";
import { 
    invoiceDateAtom, 
    contractNumberAtom,
    invoiceNumberAtom,
    documentNumberAtom,
    companyAtom,
    buyerNameAtom,
    materialAtom,
    stationAtom,
    pressNumber1Atom,
    pressNumber2Atom,
    pressNumber3Atom,
    pressNumber4Atom,
    pressNumber5Atom,
    pressNumber6Atom,
    goodsWeightAtom,
    tareAtom,
    quantityAtom,
    deliveryExpenseAtom,
    rateAtom,
    TCSAtom,
    allowanceAtom,
    insuranceAtom,
    finalAmountAtom,
    transporterAtom,
    truckNumberAtom,
    builtyNumberAtom,
    builtyDateAtom,
    pickUpNameAtom,
    pickUpAddressAtom,
    pickUpPlaceAtom,
    pickUpPinCodeAtom,
    pickUpStateAtom,
    dropNameAtom,
    dropAddressAtom,
    dropPlaceAtom,
    dropPinCodeAtom,
    dropStateAtom,
    remarksAtom,
    emailToAtom,
  } from "../states/invoicesState";

function useCreateInvoice() {
    const [invoiceDate] = useAtom(invoiceDateAtom);
    const [contractNumber] = useAtom(contractNumberAtom);
    const [invoiceNumber] = useAtom(invoiceNumberAtom);
    const [documentNumber] = useAtom(documentNumberAtom);
    const [company] = useAtom(companyAtom);
    const [buyerName] = useAtom(buyerNameAtom);
    const [material] = useAtom(materialAtom);
    const [station] = useAtom(stationAtom);
    const [pressNumber1] = useAtom(pressNumber1Atom);
    const [pressNumber2] = useAtom(pressNumber2Atom);
    const [pressNumber3] = useAtom(pressNumber3Atom);
    const [pressNumber4] = useAtom(pressNumber4Atom);
    const [pressNumber5] = useAtom(pressNumber5Atom);
    const [pressNumber6] = useAtom(pressNumber6Atom);
    const [goodsWeight] = useAtom(goodsWeightAtom);
    const [tare] = useAtom(tareAtom);
    const [quantity] = useAtom(quantityAtom);
    const [deliveryExpense] = useAtom(deliveryExpenseAtom);
    const [rate] = useAtom(rateAtom);
    const [tcs] = useAtom(TCSAtom);
    const [allowance] = useAtom(allowanceAtom);
    const [insurance] = useAtom(insuranceAtom);
    const [finalAmount] = useAtom(finalAmountAtom);
    const [transporter] = useAtom(transporterAtom);
    const [truckNumber] = useAtom(truckNumberAtom);
    const [builtyNumber] = useAtom(builtyNumberAtom);
    const [builtyDate] = useAtom(builtyDateAtom);
    const [pickUpName] = useAtom(pickUpNameAtom);
    const [pickUpAddress] = useAtom(pickUpAddressAtom);
    const [pickUpPlace] = useAtom(pickUpPlaceAtom);
    const [pickUpPinCode] = useAtom(pickUpPinCodeAtom);
    const [pickUpState] = useAtom(pickUpStateAtom);
    const [dropName] = useAtom(dropNameAtom);
    const [dropAddress] = useAtom(dropAddressAtom);
    const [dropPlace] = useAtom(dropPlaceAtom);
    const [dropPinCode] = useAtom(dropPinCodeAtom);
    const [dropState] = useAtom(dropStateAtom);
    const [remarks] = useAtom(remarksAtom);
    const [emailTo] = useAtom(emailToAtom);

    const newInvoice = {
        invoiceDate: invoiceDate ? invoiceDate.toDate().getTime() : "",
        contractNumber,
        invoiceNumber,
        documentNumber,
        company,
        buyerName,
        material,
        station,
        pressNumber1,
        pressNumber2,
        pressNumber3,
        pressNumber4,
        pressNumber5,
        pressNumber6,
        goodsWeight: parseFloat(goodsWeight),
        tare: parseFloat(tare),
        quantity: parseFloat(quantity),
        deliveryExpense,
        rate: parseFloat(rate),
        tcs,
        allowance,
        insurance,
        finalAmount: parseFloat(finalAmount),
        transporter,
        truckNumber,
        builtyNumber,
        builtyDate: builtyDate ? builtyDate.toDate().getTime() : "",
        pickUpName,
        pickUpAddress,
        pickUpPlace,
        pickUpPinCode: parseFloat(pickUpPinCode),
        pickUpState,
        dropName,
        dropAddress,
        dropPlace,
        dropPinCode: parseFloat(dropPinCode),
        dropState,
        remarks,
        emailTo,
      };

      return {
        newInvoice,
      }
}

export default useCreateInvoice;