import { useAtom } from "jotai";
import { itemListUnitAtom } from "../../states/DebitCreditNotesState";
import Select from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

function ItemListUnit() {
  const [itemListUnit, setItemListUnit] = useAtom(itemListUnitAtom);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Unit</InputLabel>
      <Select
        id="demo-simple-select"
        label="Type"
        labelId="demo-simple-select-label"
        variant="outlined"
        value={itemListUnit}
        fullWidth
        onChange={(e) => {
          setItemListUnit(e.target.value);
        }}
      >
        <MenuItem value="BAG">BAG</MenuItem>
        <MenuItem value="BAL">BAL</MenuItem>
        <MenuItem value="BDL">BDL</MenuItem>
        <MenuItem value="BKL">BKL</MenuItem>
        <MenuItem value="BOU">BOU</MenuItem>
        <MenuItem value="BOX">BOX</MenuItem>
        <MenuItem value="BTL">BTL</MenuItem>
        <MenuItem value="BUN">BUN</MenuItem>
        <MenuItem value="CAN">CAN</MenuItem>
        <MenuItem value="CBM">CBM</MenuItem>
        <MenuItem value="CCM">CCM</MenuItem>
        <MenuItem value="CMS">CMS</MenuItem>
        <MenuItem value="CTN">CTN</MenuItem>
        <MenuItem value="DOZ">DOZ</MenuItem>
        <MenuItem value="DRM">DRM</MenuItem>
        <MenuItem value="GGK">GGK</MenuItem>
        <MenuItem value="GMS">GMS</MenuItem>
        <MenuItem value="GRS">GRS</MenuItem>
        <MenuItem value="GYD">GYD</MenuItem>
        <MenuItem value="KGS">KGS</MenuItem>
        <MenuItem value="KLR">KLR</MenuItem>
        <MenuItem value="KME">KME</MenuItem>
        <MenuItem value="LTR">LTR</MenuItem>
        <MenuItem value="MTR">MTR</MenuItem>
        <MenuItem value="MLT">MLT</MenuItem>
        <MenuItem value="MTS">MTS</MenuItem>
        <MenuItem value="NOS">NOS</MenuItem>
        <MenuItem value="OTH">OTH</MenuItem>
        <MenuItem value="PAC">PAC</MenuItem>
        <MenuItem value="PCS">PCS</MenuItem>
        <MenuItem value="PRS">PRS</MenuItem>
        <MenuItem value="QTL">QTL</MenuItem>
        <MenuItem value="ROL">ROL</MenuItem>
        <MenuItem value="SET">SET</MenuItem>
        <MenuItem value="SQF">SQF</MenuItem>
        <MenuItem value="SQM">SQM</MenuItem>
        <MenuItem value="SQY">SQY</MenuItem>
        <MenuItem value="TBS">TBS</MenuItem>
        <MenuItem value="TGM">TGM</MenuItem>
        <MenuItem value="THD">THD</MenuItem>
        <MenuItem value="TON">TON</MenuItem>
        <MenuItem value="TUB">TUB</MenuItem>
        <MenuItem value="UGS">UGS</MenuItem>
        <MenuItem value="UNT">UNT</MenuItem>
        <MenuItem value="YDS">YDS</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ItemListUnit;
