import { Dispatch, SetStateAction } from "react";

export type LocalStateAction<P> = Dispatch<SetStateAction<P>>;
export type GlobalStateAction<P> = (update: SetStateAction<P>) => void;

export type CompanyData = {
    fromTrdName: Company,
    fromAddr1: string,
    fromAddr2: string,
    location: string,
    state: string,
    dispatchFromGSTIN: string,
    dispatchFromTradeName: string,
    fromGstin: string,
    pincode: number
}

export enum Company {
    AMI = "AMI TRADERS",
    HARSH = "HARSH COTTON COMPANY",
    HARDAM = "HARDAM COTTON LLP",
    JJ = "J.J. ENTERPRISE",
    EMPTY = ""
}