import { useAtom } from "jotai";
import { buyerDtlsGstinAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function BuyerDtlsGstin() {
    const [buyerDtlsGstin, setBuyerDtlsGstin] = useAtom(buyerDtlsGstinAtom);

    return <TextField
    id="outlined-basic"
    label="Gstin"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={buyerDtlsGstin}
    onChange={(e) => {
        setBuyerDtlsGstin(e.target.value);
    }}
  />;
};

export default BuyerDtlsGstin;