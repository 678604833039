import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import { builtyNumberAtom } from "../../states/invoicesState";

function BuiltyNumber() {
    const [builtyNumber, setBuiltyNumber] = useAtom(builtyNumberAtom);

    return (
        <TextField
        id="outlined-basic"
        label="Builty No."
        variant="outlined"
        autoComplete="off"
        fullWidth
        value={builtyNumber}
        onChange={(e) => {
          setBuiltyNumber(e.target.value);
        }}
      />
    );
}

export default BuiltyNumber;