import { useAtom } from "jotai";
import { weightShortageAtom } from "../../states/DebitCreditNotesState";
import TextField from "@mui/material/TextField";

function WeightShortage() {
    const [weightShortage, setWeightShortage] = useAtom(weightShortageAtom);

    return <TextField
    id="outlined-basic"
    label="Weight Shortage"
    variant="outlined"
    autoComplete="off"
    fullWidth
    value={weightShortage}
    onChange={(e) => {
        setWeightShortage(e.target.value);
    }}
  />;
};

export default WeightShortage;