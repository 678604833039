import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CompletedInvoiceData } from "../models";
import Grid from "@mui/material/Unstable_Grid2";
import { handleFetchCompletedInvoices } from "../helpers";
import { useAtom } from "jotai";
import { selectedCompletedInvoiceAtom, showDialogBoxAtom } from "../states/invoicesState";
import CompletedDialogBox from "./CompletedDialogBox";

function CompletedInvoiceList() {
  const [completedInvoices, setCompletedInvoices] = useState<
    CompletedInvoiceData[]
  >([]);
  const [, setSelectedCompletedInvoice] = useAtom(selectedCompletedInvoiceAtom);
  const [, setShowDialogBox] = useAtom(showDialogBoxAtom);

  useEffect(() => {
    (async () => {
      const CompletedInvoicesData = await handleFetchCompletedInvoices();
      setCompletedInvoices(CompletedInvoicesData);
    })();
  }, []);

  function fetchMoreCompletedInvoiceData() {
    // setFetching(true);
    setTimeout(() => {
      console.log("fetching more completed");
      // setCompletedInvoices([
      //   ...completedInvoices,
      //   ...Array.from({ length: 20 }),
      // ]);
      // setFetching(false);
    }, 1500);
  }
  function handleSingleClick(completedInvoice: CompletedInvoiceData) {
    setSelectedCompletedInvoice(completedInvoice);
    setShowDialogBox(true);
  }

  return (
    <List
      id="completed-invoice-list"
      style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
    >
      <InfiniteScroll
        dataLength={completedInvoices.length}
        next={() => {
          // if (!fetching) {
          fetchMoreCompletedInvoiceData();
          // }
        }}
        hasMore={true}
        scrollableTarget="completed-invoice-list"
        loader={
          <div>
            <LinearProgress />
          </div>
        }
        endMessage={<p>No more data to load.</p>}
      >
        {completedInvoices.map((completedInvoice, index) => [
          <ListItemButton
            key={"list-item" + index}
            onClick={() => {
              handleSingleClick(completedInvoice);
            }}
          >
            <Grid container width="100%">
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="completed-invoice-invoice-date"
              >
                {completedInvoice.companyName}
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="completed-invoice-invoice-number"
              >
                {completedInvoice.buyerName}
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="completed-invoice-buyer-name"
              >
                {completedInvoice.contractNumber}
              </Grid>
              <Grid
                xs={12}
                sm={6}
                md={3}
                className="completed-invoice-company-name"
              >
                {completedInvoice.quantity}
              </Grid>
            </Grid>
          </ListItemButton>,
          <Divider
            key={"list-item-divider-" + index}
            variant="middle"
            component="li"
          />,
        ])}
      </InfiniteScroll>
      <CompletedDialogBox />
    </List>
  );
}

export default CompletedInvoiceList;
